import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Applyingnew.css'

const FormHeader = () => {
  
  return (
    <>
   <div class="topbar flex flex-row flex-jc-center" data-test="topbar"><div class="oneclick-wrapper"><div class="flex flex-row flex-jc-space-between flex-ai-center"><div><img class="brand-logo" src={'../assets/images/canvendor_logo.jpg'} width={150} alt="Freshworks Logo" data-test="topbar-logo"/></div><div class="topbar-job-details"><p class="font--x-bold margin-vertical--s" data-test="topbar-job-title">Senior Director, Integrated Campaigns</p><p class="margin-vertical--s flex flex-ai-center" data-test="topbar-location"><i class="icon icon--oc-location topbar-location-icon" aria-label="Location"></i>Bellevue, WA, USA</p></div></div></div></div>
    </>
    
  )
}

export default FormHeader