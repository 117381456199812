import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavComponent from "../../components/NavComponent";
import Applyingnew from "./Applyingnew";
const Career = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [buttonStyle, setButtonStyle] = useState({
    float: "right",
    maxWidth: "200px",
    backgroundColor: "#0E0E47",
    color: "#fff",
    marginBottom: "25px",
  });

  const handleHover = () => {
    setButtonStyle({
      float: "right",
      maxWidth: "200px",
      border: "1px solid #47b2e4",
      color: "#47b2e4",
      marginBottom: "25px",
    });
  };
  const handleMoverLeave = () => {
    setButtonStyle({
      float: "right",
      maxWidth: "200px",
      backgroundColor: "#47b2e4",
      color: "#fff",
      marginBottom: "25px !important",
    });
  };
  return (
    <>
      <NavComponent></NavComponent>

      <div className="careers-bg">
        <div className="container careers-section-container">
          <div className="row">
            <div className="col-lg-7 careers-content">
              <h2 className="section-sub-title">Careers</h2>
              <p className="careers-page-para">
                At the core of the impact that
                <br /> we create as a team is the outstanding mix of different
                backgrounds, skills, and ways of creating value that we bring
                together.
              </p>
              <div className="careers-page-btn-content">
                <h5 className="careers-page-btn-title">Explore Your Role</h5>
                <Link to="/contactUs"><button className="careers-page-btn">Contact Us</button></Link>
              </div>
            </div>
            <div className="col-lg-5 careers-page-img">
              <img src="/careers page img.png" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
