import React, { useEffect, useState } from "react";
import HomeServices from "../components/home/HomeServices";
import NavComponent from "../components/NavComponent";
import { AiOutlineCloudServer } from "react-icons/ai";
import { GiArtificialIntelligence } from "react-icons/gi";
import { BsBuilding } from "react-icons/bs";
import { CgWebsite } from "react-icons/cg";
import { DiDigitalOcean } from "react-icons/di";
import { Link } from "react-router-dom";
import jsonData from "../blog.json";

const resoCardItem = [
  {
    img: `/Healthcare Revolution.jpg`,
    title: `Navigating the Digital Healthcare Revolution`,
    link: ``,
  },
  {
    img: `/data security.jpg`,
    title: `Understand the critical aspects of data security`,
    link: ``,
  },
  {
    img: `/data security.jpg`,
    title: `Explore technologies that complement EMRs`,
    link: ``,
  },
  {
    img: `/data security.jpg`,
    title: `Explore technologies that complement EMRs`,
    link: ``,
  },
];

const InsightPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(jsonData);
  }, []);
  return (
    <>
      <NavComponent></NavComponent>{" "}
      <div className="container reso-title-container">
        <p className="section-sub-title">Resources</p>
        <h1>Explore Our Resources</h1>
      </div>
      <div className="container reso-item-bg-container">
        <div className="reso-item-grid">
          {resoCardItem.map((item) => (
            <div className="reso-item">
              <div className="reso-item-img">
                <img src={item.img} />
              </div>
              <div className="reso-item-content">
                <h1 className="reso-item-title">{item.title}</h1>
                <Link to={item.link}>
                  <div className="reso-rm-btn">
                    <p>Read More</p>
                    <img src="/arrow-right.png" />
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* {data.map((item) => (
        <>
          <div className="SingleService" id="SingleService" key={item.blog}>
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="tp-section-wrapper">
                    <span className="tp-section-subtitle tp-subtitle-before mb-3">
                      Our misson is grow your Business
                    </span>
                    <h2 className="tp-section-title tp-section-title-large">
                      {item.heading}
                    </h2>
                    <p> {item.content1}</p>
                    <p>{item.content2}</p>{" "}
                  </div>
                </div>
                <div className="col-lg-4 d-flex justify-content-lg-end">
                  <img
                    src="../assets/images/blog/blog-1.jpg"
                    style={{ maxWidth: "100%", height: "auto" }}
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </>
      ))} */}
    </>
  );
};

export default InsightPage;
