import React from "react";
import NavComponent from "../../components/NavComponent";
import { useEffect, useState, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const BankingAndFinance = () => {
  useEffect(() => {   
    window.scrollTo(0, 0); 
   }, []);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <NavComponent></NavComponent>

      <div class="indus-page-title-container bank-indus-bg-img">
        <div class="container indus-title-content-container">
          <h2 className="indus-page-title" data-aos="flip-down">
            Banking
          </h2>
          <p className="indus-page-tagline" data-aos="fade-up">
            {" "}
            Innovative Banking Solutions for a Seamless and Secure Experience
          </p>
        </div>
      </div>

      <div class="container indus-content-section">
        <div class="row">
          <div class="col-lg-9">
            <span class="section-sub-title">Banking</span>
            <h2 class="indus-content-title">
              The Rise of Digital Finance and its Impact on Banking Services
            </h2>
            <p>
              {" "}
              Digital finance harnesses technology to deliver secure,
              convenient, and accessible financial services, reshaping
              traditional banking methods and expanding global financial
              inclusion.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <p>
              In the modern era, banking applications exemplify the integration
              of finance and technology. These apps empower users with
              exceptional convenience and control over their finances,
              fundamentally transforming money management.{" "}
            </p>
            <p class="text-dark">
              {" "}
              Banking apps feature user-friendly interfaces and provide instant
              access to account balances, transaction histories, and a broad
              range of banking services. Advanced security measures protect
              users' sensitive information, including biometric and multi-factor
              authentication and robust encryption.
            </p>
            <p class="text-dark">
              {" "}
              Banking applications streamline fund transfers, bill payments, and
              investment management from any device. With real-time alerts and
              notifications, users stay informed about their financial
              activities, enhancing financial literacy and promoting responsible
              spending. In summary, banking apps have revolutionized banking,
              offering efficient, secure, and intuitive financial management
              solutions for millions worldwide.
            </p>
          </div>
        </div>
      </div>

      <div class="container indus-our-services-section">
        <h1 class="indus-content-title">Our Offerings</h1>
        <p className="mb-5">
          Access state-of-the-art banking and payment applications that bring
          efficiency and security to your financial transactions.
        </p>

        <div class="indus-service-grid">
          <div class="indus-service-item">
            <div class="tp-service__icon"></div>
            <h3 class="indus-service-item-title">Mobile Banking Application</h3>
            <p className="indus-service-item-para">
              Our mobile banking application is designed for seamless access to
              banking services on smartphones and tablets. Users can manage
              their accounts, transfer funds, pay bills, and handle various
              financial transactions directly from their mobile devices.
            </p>
          </div>
          <div class="indus-service-item">
            <div class="tp-service__icon"></div>
            <h3 class="indus-service-item-title">Online Banking Application</h3>
            <p className="indus-service-item-para">
              Our online banking application, or Internet banking, offers a
              secure web-based platform for managing financial transactions.
              Customers can log in through a protected website to access their
              accounts, perform transactions, and utilize banking services from
              any internet-enabled device.
            </p>
          </div>
          <div class="indus-service-item">
            <div class="tp-service__icon"></div>
            <h3 class="indus-service-item-title">Digital Wallet Application</h3>
            <p className="indus-service-item-para">
              Our digital wallet application, or e-wallet, securely stores
              payment card details, digital currencies, and loyalty cards. It
              enables users to conduct electronic transactions both online and
              in physical stores using their mobile devices, ensuring a
              convenient and secure payment experience.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankingAndFinance;
