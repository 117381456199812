import React from 'react'
import { Card, CardImg } from 'react-bootstrap'

function IndustryComponent(props) {
    const styles = {
        card:{
          marginTop:'20px',
          marginBottom:'30px',
          paddingTop:'2px',
          // width: '25vh',
          height:'25vh',
          width:'250px',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          cursor:'pointer',
          
          // backgroundColor:'rgba(255, 255, 255, 0.564)',
          // backgroundColor:'#dfdbdbf4',
      border:'2px solid #a7e6ff',
      boxShadow:'5px 5px 10px 0px gray'
        },
        userImg: {
          transition: 'all 0.2s ease-in-out',
          cursor: 'pointer'
        },
        userImgHover: {
          transform: 'scale(1.05)',
          filter: 'brightness(120%)'
        }
      };
  return (
    <>
    <Card style={styles.card} className='industry-card aos-init aos-animate'>
      <Card.Img variant="top" src={props.image} style={{ width: '15vh',height:'15vh',filter: 'drop-shadow(5px 5px 6px #555353ad)',marginBottom:'30px'}} />
      <Card.Body>
        <Card.Title>{props.name}</Card.Title>
      </Card.Body>
    </Card>
    {/* <Card>
      <CardImg style={styles.userImg} src={props.image} 
        onMouseOver={e => e.currentTarget.style = styles.userImgHover}
        onMouseOut={e => e.currentTarget.style = styles.userImg} />
      <Card.ImgOverlay className="bg-overlay">
        <Card.Title>{props.name}</Card.Title>
      </Card.ImgOverlay>
    </Card> */}
    
    </>
  )
}

export default IndustryComponent