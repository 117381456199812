import React from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Typewriter from "typewriter-effect";
import NavComponentHome from "../NavComponentHome";
import AOS from "aos";
import "aos/dist/aos.css";

const Banner = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);
  //example showing all options
  // let ps = new ParticleScript('#ps', {
  //   count: 300,
  //   colors: ['rgba(255,0,0,0.75)', 'rgba(0,255,0,0.75)', 'rgba(0,0,255,0.75)', 'rgba(0,255,255,0.75)', 'rgba(255,0,255,0.75)', 'rgba(255,255,0,0.75)'],
  //   mouseMovementXSpeed: 0.5,
  //   minSpeed: 0.1,
  //   maxSpeed: 0.25,
  //   //this stuff is only used if we are basing speed on size of particles
  //   speedBasedOnSize: true,
  //   sizeSpeedMultiplier: 0.25,
  //   //this stuff is only used if we AREN'T basing speed on size of particles
  //   minSize: 1,
  //   maxSize: 10
  // })

  //GUI Stuff
  // let gui = new dat.GUI()
  // let general = gui.addFolder('General')

  // general.add(ps, 'count', 1, 1000).step(1).onChange(() => { ps.reset() })
  // general.add(ps, 'colorText').onChange((value) => {
  //   ps.colors = value.split(' ')
  // })
  // general.open()

  // var size = gui.addFolder('Size')
  // size.add(ps, 'minSize', 1, 100)
  // size.add(ps, 'maxSize', 1, 100)
  // size.open()

  // var speed = gui.addFolder('Speed')
  // speed.add(ps, 'speedBasedOnSize').onChange(() => {
  //   ps.reset()
  // })
  // speed.add(ps, 'sizeSpeedMultiplier', -2, 2).step(0.01)
  // speed.add(ps, 'minSpeed', -20, 20)
  // speed.add(ps, 'maxSpeed', -20, 20)
  // speed.add(ps, 'mouseMovementXSpeed', 0, 1).step(0.01)
  // speed.open()

  // gui.add(ps, 'reset')

  return (
    <>
      <NavComponentHome></NavComponentHome>
      <div
        id="homecon"
        className="homepart  pt-4"
        // data-aos="flip-up"
        style={{
          
        }}
      >
        <div className="container">
          <div className="home-banner-content">
            <h1 className="banner-title" data-aos="flip-down">
              Innovate Transform.
              <br /> Thrive: Canvendor Leads the Way
            </h1>
            <p className="banner-tagline" data-aos="fade-up">
              Empower Your Business for Tomorrow's Success with Canvendor's
              Digital Expertise
            </p>
            <Link to="/services">
              <button className="btn explore-btn" data-aos="fade-up">
                Explore Our Services
                <img
                  className="explore-arrow"
                  src="explore-arrow.png"
                  alt="#"
                />
              </button>
            </Link>
          </div>
        </div>
        <img data-aos="fade-left" data-aos-duration="3000" className="banner-img" src="/banner-img.png" />
      </div>
    </>
  );
};

export default Banner;
