import React from "react";
import ContactCard from '../components/ContactCard';
import {
  BsArrowRight,
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
} from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* <div className='tp-theme-btn-area pt-90 pb-140' style={{backgroundColor:'white'}}>
        <div className='container'>
            
            <div className='row justify-content-center p-relative'>
                <div className='col-lg-12 d-flex justify-content-center'>
                <Link to='/contactUs'>  <a className='getintouch' ><div className='tp-theme-btn d-flex align-items-center white-bg'>
                        <div className='tp-btn-inner'> <p>Let’s Talk</p> <b>Get in Touch</b>
                        <span className='d-inline-block'><img src='../arrow.svg'></img></span></div></div></a></Link>
                </div>
            </div>
        </div>
       </div> */}
<ContactCard/>
      <div class="overly">
        <div class="container"></div>
      </div>

      <footer id="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row">
              {/* <div class="col-lg-3 col-md-6 footer-contact">
                <img src={"../assets/images/canvendor_blue_transparent_logo.png"} width={"150px"}></img>
                <br></br>
                <br></br>

                <h4 className="text-white ms-3 mt-3">Our Social Networks</h4>
                <p></p>
                <div class="social-links mt-3 ms-3">
                  <a href="https://twitter.com/Canvendor_Inc" class="twitter">
                    <BsTwitter></BsTwitter>
                  </a>
                  <a href="https://www.facebook.com/canvendor" class="facebook">
                    <BsFacebook />
                  </a>
                  <a
                    href="https://www.instagram.com/canvendor_inc/"
                    target="_blank"
                    class="instagram"
                  >
                    <BsInstagram></BsInstagram>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/canvendor/"
                    target="_blank"
                    class="google-plus"
                  >
                    <BsLinkedin></BsLinkedin>
                  </a>
                </div>
              </div> */}

              <div className="col-lg-3 col-md-6 newsletter-content">
              <h4 className="">Connect With Us</h4>
              <div class="social-links mt-3">
                  <a href="https://twitter.com/Canvendor_Inc" class="twitter">
                    <BsTwitter></BsTwitter>
                  </a>
                  <a href="https://www.facebook.com/canvendor" class="facebook">
                    <BsFacebook />
                  </a>
                  <a
                    href="https://www.instagram.com/canvendor_inc/"
                    target="_blank"
                    class="instagram"
                  >
                    <BsInstagram></BsInstagram>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/canvendor/"
                    target="_blank"
                    class="google-plus"
                  >
                    <BsLinkedin></BsLinkedin>
                  </a>
                </div>
                <h3 className="newsletter-title mt-4">Join Our Newsletter</h3>{" "}
                {/* <p>Stay in the loop—join our newsletter today!</p> */}
                <form action="" method="post" className="newsletter-form mb-4">
                  <input
                    className="newsletter-input"
                    type="email"
                    name="email"
                    placeholder="Your email"
                  />
                  <button className="newsletter-btn" type="submit" value="Send">
                    {/* <img src="/newsletter-send.png" /> */}
                    Subcribe
                  </button>
                </form>
              </div>
              {/* footer Navlinks */}
              <div class="col-lg-3 col-md-6 footer-links">
                <h4 className="">Useful Links</h4>
                <ul>
                  <li className="link-before">
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/about"> Home</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/about"> About us</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/services#service"> Capabilities</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/carrer"> Careers</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/Terms"> Terms of service</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/privacyPolicy"> Privacy policy</Link>
                  </li>
                </ul>
              </div>

              {/* footer Services */}
              <div class="col-lg-3 col-md-6 footer-links">
                <h4 className="text-white">Capabilities</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/services/aiml">AI / ML Solutions</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/services/webAppService">
                      Application Engineering
                    </Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/services/cloudSolutions">Cloud Solutions</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/services/digitalTransformation">
                      Digital Transformation Services
                    </Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/services/enterpriseService">
                      Enterprises Solutions
                    </Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/services/itConsultingService">
                      IT Consulting
                    </Link>
                  </li>
                  
                  
                </ul>
              </div>
              {/* footer Industries */}
              <div class="col-lg-3 col-md-6 footer-links">
                <h4 className="text-white">Industries</h4>
                <ul>
                  
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/industries/automotive">Automotive</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/industries/banking&finance">
                      Banking
                    </Link>
                  </li>
                  {/* <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/industries/communications&information">
                      Communication
                    </Link>
                  </li> */}
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/industries/healthcare">Healthcare</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/industries/insurance">Insurance</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/industries/manufacturing">Manufacturing</Link>
                  </li>
                </ul>
              </div>

              {/* footer social Media Links */}
              {/* <div class="col-lg-3 col-md-6 footer-links">
                <h4 className="text-white">Newsletter</h4>
                <div
                  class="footer-newsletter"
                  style={{ padding: "0px 0px !important" }}
                >
                  <form action="" method="post" id="letTalk">
                    <input type="email" name="email" />
                    <input type="submit" value="Send" />
                  </form>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div
          class="container footer-bottom clearfix"
          id="cpy"
          style={{ position: "relative", zIndex: "2" }}
        >
          <div class="copyright ">
            {" "}
            © Copyright{" "}
            <strong>
              <span>Canvendor</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
