import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Applyingnew.css'
const Experience = (props) => {
  const {initialData,indexKey} = props
  const [expValues, setExpValues] = useState([{ jobTitle:"",comName:"",location:"",strDate:"",endDate:"",description:""}])
  return (
    <>
    <div className='container'>
    <div className='personalDetail form-section'>
       

<form class="row g-3">
<div class="col-md-6">
    <label for="inputEmail4" class="form-label">Title</label>
    <input type="text" class="form-control" id="inputEmail4"  value={expValues.jobTitle} onChange={e => setExpValues({...expValues,"jobTitle":e.target.value})}/>
  </div>
  <div class="col-md-6">
    <label for="inputPassword4" class="form-label">Company</label>
    <input type="text" class="form-control" id="inputPassword4"   value={expValues.comName} onChange={e => setExpValues({...expValues,"comName":e.target.value})}/>
  </div>
  <div class="col-md-6">
    <label for="inputEmail4" class="form-label">Office location</label>
    <input type="email" class="form-control" id="inputEmail4"   value={expValues.location} onChange={e => setExpValues({...expValues,"location":e.target.value})}/>
  </div>
  <div class="col-md-6">
  <label for="inputEmail4" class="form-label">Description</label>
    <input type="email" class="form-control" id="inputEmail4" value={expValues.description} onChange={e => setExpValues({...expValues,"description":e.target.value})}/>
  </div>
  <div class="col-md-6">
    <label for="inputEmail4" class="form-label">From</label>
    <input type="date" class="form-control" id="inputEmail4" value={expValues.strDate} onChange={e => setExpValues({...expValues,"strDate":e.target.value})}/>
  </div>
  <div class="col-md-6">
  <label for="inputEmail4" class="form-label">To</label>
    <input type="date" class="form-control" id="inputEmail4" value={expValues.endDate} onChange={e => setExpValues({...expValues,"endDate":e.target.value})}/>
  </div>


  
</form>
<button
                    shape="circle"
                    size="sm"
                    variant="twoTone"
                  
                    className=" mt-5 ml-auto"
                    // onClick={() => saveExperi()}
                >Save</button>
    </div>
 
    </div>
 
    </>
    
  )
}

export default Experience