import React from "react";
import NavComponent from "../../components/NavComponent";
import "../services/service-css/style.css";
import { Link } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const CloudService = () => {
  useEffect(() => {   
    window.scrollTo(0, 0); 
   }, []);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <NavComponent></NavComponent>
      <section className="cloud-services-page-head-bg overflow-hidden">
        <div className="container service-bg-container ">
          <div>
            <h1 className="service-inner-page-title" data-aos="flip-down">
              Cloud Solutions
            </h1>
            <div className="page-direction" data-aos="fade-up">
              <span className="ps-0">
                <Link to="/">Home</Link>
              </span>
              <span>
                <Link to="/services">capabilities</Link>
              </span>
              <p>Cloud Solutions</p>
            </div>
          </div>
          <img
            className="cloud-img"
            src="/cloud-services-page-img.jpg"
            data-aos="fade-left"
            data-aos-duration="3000"
          />
        </div>
      </section>
      <div className="container service-content-section">
        <div className="row">
          <div className="col-lg-10">
            <div className="">
              <span className="section-sub-title">
                Our misson is grow your Business
              </span>
              <h2 className="service-page-content-title">
                Transformative Cloud Solutions for Modern Businesses{" "}
              </h2>
              <p>
                {" "}
                At Canvendor, we understand that cloud solutions have
                transformed IT resource management, making it more efficient and
                flexible than ever. Embracing cutting-edge technology like cloud
                computing can revolutionize your business operations. With
                real-time reporting and the ability to create a customized,
                client-focused user experience, cloud solutions are essential
                for streamlining processes and driving future growth.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid cloud-our-offerings">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h1 className="service-page-offering-title">Our Offerings</h1>
              <p className="service-page-offering-para">
                At Canvendor, we deliver a suite of advanced cloud solutions
                designed to elevate your IT infrastructure. Our cloud services
                ensure seamless integration, enhanced performance, and strategic
                transformation to meet your evolving business needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container service-card-margin">
        <div className="service-offering-section">
          <div className="service-offering-card">
            <div className="">
              <div className="service-offering-card-icon">
                <img src="/Core Infrastructure Management.png" />
              </div>
              <h3 className="service-offering-card-title">
                Core Infrastructure Management
              </h3>
              <p className="service-offering-card-content">
                To leverage technological advancements effectively, companies
                must establish a robust IT infrastructure and ensure cloud
                readiness. Our Core Infrastructure Management (CIM) services
                enhance business value by updating existing systems, providing a
                fresh perspective on your operations, and transforming user
                experiences with advanced technology solutions.
              </p>
            </div>
          </div>
          <div className="service-offering-card">
            <div className="">
              <div className="service-offering-card-icon">
                <img src="/Infrastructure Modernization and Management.png" />
              </div>
              <h3 className="service-offering-card-title">
                Infrastructure Modernization and Management
              </h3>
              <p className="service-offering-card-content">
                At Canvendor, our agile team excels in modernizing and managing
                cloud infrastructure. We are your trusted partner in digital
                transformation, utilizing cutting-edge methods and expert
                knowledge to drive and deliver exceptional results. Our approach
                integrates the latest technological advancements to optimize
                your cloud infrastructure.
              </p>
            </div>
          </div>
          <div className="service-offering-card">
            <div className="">
              <div className="service-offering-card-icon">
                <img src="/Improving Workplace Performance.png" />
              </div>
              <h3 className="service-offering-card-title">
                Improving Workplace Performance
              </h3>
              <p className="service-offering-card-content">
                Canvendor is dedicated to revolutionizing legacy work
                environments into digitally advanced workplaces. By upgrading to
                modern IT infrastructure, we enable seamless onboarding of new
                users with innovative self-healing software and self-service
                capabilities, accelerating transformation and boosting workplace
                performance.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container service-content-section">
        <div className="row">
          <div className="col-lg-10">
            <div className="">
              <span className="section-sub-title">Consulting</span>
              <h2 className="service-page-content-title">Cloud Solutions </h2>
              <p>
                {" "}
                At Canvendor, we offer advanced Cloud Solutions tailored to meet
                your business needs. Our team excels in addressing diverse
                challenges during your digital transformation journey. We focus
                on closing gaps and creating efficient cloud strategies,
                ensuring your business benefits from enhanced scalability and
                operational efficiency. Trust our expertise to drive your growth
                with customized cloud solutions designed for innovation and
                success.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='container'>
               <div className='row'>
               <div className='col-lg-9'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Consulting</span>
        <h2 className='tp-section-title tp-section-title-large'> 

        AI/ ML SOLUTIONS  </h2>
<p> At Canvendor, IT consulting is our forte. Our team is empowered to solve an array of business snags at any stage of digital transformation. The team is adept at eliminating gaps and building smooth-sailing roadmaps that give our customers a competitive advantage over their competition. </p>
    </div>
</div>
<div className='col-lg-3 d-flex justify-content-lg-end'>
    <img src='../robotic-automation.png' width={250}></img>
</div>
               </div>
                </div> */}
      {/* <section className='page-banner pt-xs-60 pt-sm-80 overflow-hidden'>
	<div className='container'>
		<div className='row align-items-center pt-5'>
			<div className='col-md-6 pt-5'>
				<div className='page-banner__content mb-xs-10 mb-sm-15 mb-md-15 mb-20'>
					<div className='transparent-text'>Service</div>
					<div className='page-title'><h1>Cloud <span>Service</span>	</h1>
					</div>
				</div>
			</div>
			<div className='col-md-6 pt-5'>
				<div className='mt-xs-30 mt-sm-40'>
					<img src='../cloudnew.jpg' width={300} className='img-fluid'></img>
				</div>
			</div>
		</div>
	</div>
</section>

<div className='company-skill pt-xs-80 pb-xs-80 pt-sm-100 pt-md-100 pt-120 pb-100 overflow-hidden'>

	<div className='container'>
		<div className='row'>
			<div className='col-xl-6'>
				<div className='company-skill__content'>
					
					<h2 className='title color-pd_black mb-25 mb-xs-10 mb-sm-15'>Cloud Solutions </h2>
					<div className='description font-la'><p>The cloud solution is a discovery that has made managing IT resources a cakewalk. They are a functional and most potent way to run your business. Adapting to the latest tech advancements like Cloud to bring about a change in the way your company works with real-time reporting and creating a bespoke client-focused user experience is the way ahead.</p>
<p>Creating cloud-powered businesses is at the core of Canvendor’s approach to digital transformation and we aim at acclimatizing our customers to succeeding in vibrant markets. We focus on the resources on hand and simplify the processes as much as we can to achieve the maximum outcome for the clients. </p></div><br></br>
<h4 className='title mb-25 mb-xs-10 mb-sm-15'>Improving workplace performances</h4>
					<div className='description font-la'><p>Canvendor’s agile team is an expert across the vital platforms of the cloud making us a trusted digital transformation partner in driving and delivering results. Cloud infrastructure modernization and maintenance is a combination of the latest technologically advanced methods, subject matter experts, and leading engineers to empower industry-focused results and user engagement. 

</p>
</div>
				</div>
			</div>
			<div className='col-xl-6'>
				<div className='company-skill__media-wrapper d-flex flex-column mt-lg-60 mt-md-50 mt-sm-45 mt-xs-40 align-items-center'>


					<div className='company-skill__media'>
						<img src='http://www.canvendor.com/assets/images/services/app-modirazation1.png' className='img-fluid'></img>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<section className='why-choose pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-110 overflow-hidden'>
    <div className='container'>
        <div className='row'>
            <div className='col-xl-6'>
                <div className='why-choose__media-wrapper d-flex flex-column'>
                    <div className='gallery-bar'></div>
                    <div className='why-choose__media'>
                        <img src='http://www.canvendor.com/assets/img/blog/blog-3.jpg' className='img-fluid'></img>
                    </div>
                    <div className='global-country text-center'></div>
                </div>
            </div>
            <div class="col-xl-6">
                    <div class="why-choose__content mt-lg-60 mt-md-50 mt-sm-40 mt-xs-35">
                        <div class="why-choose__text mb-40 mb-md-35 mb-sm-30 mb-xs-30">
                       
                            <h2 class="title color-pd_black">CANVENDOR’S OFFERINGS </h2>
                            
               
                        </div>
                        <h4 className='title  mb-25 mb-xs-10 mb-sm-15'>Infrastructure Modernization and management   </h4>
					<div className='description font-la'><p>Canvendor’s agile team is an expert across the vital platforms of the cloud making us a trusted digital transformation partner in driving and delivering results. Cloud infrastructure modernization and maintenance is a combination of the latest technologically advanced methods, subject matter experts, and leading engineers to empower industry-focused results and user engagement. 
</p>

<p>Creating cloud-powered businesses is at the core of Canvendor’s approach to digital transformation and we aim at acclimatizing our customers to succeeding in vibrant markets. We focus on the resources on hand and simplify the processes as much as we can to achieve the maximum outcome for the clients. </p>
</div>

                    </div>
                </div>
        </div>
    </div>
</section> */}

      {/* <NavComponent></NavComponent>
      <section id="pricing" className="pricing amiservice">
      <div className="section-title-serviceCloud" data-aos='zoom-in'>
        <div className='container'>
     
        <h2>Cloud solutions</h2>
            <p className='aiml-service-content'>  The cloud solution is a discovery that has made managing IT resources a cakewalk.
                    They are a functional and most potent way to run your business.
                    Adapting to the latest tech advancements like Cloud to bring about a change in the way your company works with real-time reporting and creating a bespoke client-focused user experience is the way ahead.
                    Bringing the company's IT resources under one secure cloud platform is exactly where the processing power should be.</p></div>
        </div>
        
 
 <div class="container-fluid p-0" >

    <div className='container'>
    <div className="row-content row g-0" >
        <div className="col-lg-6 pt-5 mt-4" data-aos='fade-right'>
        <h3 className='appmo ps-0'> Core infrastructure management</h3>
                <p className='amip ps-0'>
                To be able to enjoy technological advancements, companies need to build a strong IT infrastructure and be cloud-ready.
                    CIM is a business enabler and can extract value from existing systems by updating technology, envisaging the business in a new light, and transforming the user experience.
                    CIM encompasses aspects like data center consolidation and hybrid cloud implementation and it also promotes a cloud-smart technology.
                    A responsive IT infrastructure aids in adapting and scaling within in safe hybrid or singular cloud platform.
                </p>
             
        </div>
        <div className="col-lg-4">
        <img src={'http://www.canvendor.com/assets/images/services/cloud-1.png'} style={{maxHeight:"500px",borderRadius:'10px',marginLeft:'100px'}}/>
               
            </div>
            
            
            </div>

        </div>
        <div class="container-fluid p-0"style={{
background: 'linear-gradient(90deg, rgba(14,14,96,1) 35%, rgba(92,96,180,1) 100%)',height:'400px'}}>
        <div className='container pb-5'>
        <div className="row-content row g-0  pb-5" >
    
        <div className="col-lg-5 p-0">
                  <img className='img-fluid' src={'https://dummyimage.com/540x303/de42de/fff.jpg'} style={{height:'300px',marginTop:'40px'}}/>
        </div>
        <div className="col-lg-5 mt-5 pt-3" data-aos='fade-left' style={{marginLeft:'80px'}}>
                <h3 className='appmo ps-0'  style={{color:'white'}}> Infrastructure Modernization</h3>
                <p  className='amip ps-0' style={{color:'white'}}>
                Canvendor’s agile team is an expert across the vital platforms of the cloud making us a trusted digital transformation partner in driving and delivering results.
                    Cloud infrastructure modernization and maintenance is a combination of the latest technologically advanced methods, subject matter experts user engagement.
                </p>
          

            </div>
            
            </div>
</div>

        </div>
        <div className='container'>
    <div className="row-content row g-0" >
        <div className="col-lg-6 pt-5 mt-4" data-aos='fade-right'>
        <h3 className='appmo ps-0'>Improving workplace performances</h3>
                <p className='amip ps-0'>
                Canvendor aims at accelerating the transforming the legacy work environment into a completely digitally advanced workplace. Updating to a new IT infrastructure aids in the swift and intelligent onboarding of new users with self-healing software and self-service capabilities. 
                    A responsive IT infrastructure aids in adapting and scaling within in safe hybrid or singular cloud platform.
                </p>
             
        </div>
        <div className="col-lg-4">
        <img src={'http://www.canvendor.com/assets/images/services/cloud-1.png'} style={{maxHeight:"500px",borderRadius:'10px',marginLeft:'100px'}}/>
               
            </div>
            
            
            </div>

        </div>
  



   
        </div>
   
</section> */}
    </>
  );
};

export default CloudService;
