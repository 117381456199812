import React from "react";
import NavComponent from "../../components/NavComponent";
import "../services/service-css/style.css";
import { Link } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const ItSolution = () => {
  useEffect(() => {   
    window.scrollTo(0, 0); 
   }, []);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <NavComponent></NavComponent>
      <section className="it-services-page-head-bg overflow-hidden">
        <div className="container service-bg-container ">
          <div>
            <h1 className="service-inner-page-title" data-aos="flip-down">
              IT Consulting
            </h1>
            <div className="page-direction" data-aos="fade-up">
              <span className="ps-0">
                <Link to="/">Home</Link>
              </span>
              <span>
                <Link to="/services">capabilities</Link>
              </span>
              <p>IT Consulting</p>
            </div>
          </div>
          <img
            className="it-img"
            src="/it-services-page-img.png"
            data-aos="fade-left"
            data-aos-duration="3000"
          />
        </div>
      </section>

      <div className="container service-content-section">
        <div className="row">
          <div className="col-lg-10">
            <div className="">
              <span className="section-sub-title">
                Our misson is grow your Business
              </span>
              <h2 className="service-page-content-title">
                IT Consulting & Support{" "}
              </h2>
              <p>
                At Canvendor, we bring together top IT experts to deliver
                comprehensive IT consulting and support solutions. Our team’s
                deep technical expertise and industry knowledge empower us to
                craft customized strategies for digital transformation, aligning
                seamlessly with your business objectives. Discover how our IT
                consulting services can drive innovation and efficiency,
                ensuring your technology investments yield maximum results.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid it-our-offerings">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h1 className="service-page-offering-title">
                Our IT Consulting Process{" "}
              </h1>
              <p className="service-page-offering-para">
                Our IT Consulting Process involves a thorough analysis of your
                current IT systems, strategic planning for enhanced
                infrastructure, and a focus on collaborative performance to
                drive successful business outcomes.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container service-card-margin">
        <div className="service-offering-section">
          <div className="service-offering-card">
            <div className="">
              <div className="service-offering-card-icon">
                <img src="/Analysis.png" />
              </div>
              <h3 className="service-offering-card-title">Analysis</h3>
              <p className="service-offering-card-content">
                Our expert software strategists analyze your current IT
                infrastructure, evaluating its application across various
                systems within your organization. We identify workflow
                roadblocks and automation opportunities to enhance efficiency.
              </p>
            </div>
          </div>
          <div className="service-offering-card">
            <div className="">
              <div className="service-offering-card-icon">
                <img src="/Strategy.png" />
              </div>
              <h3 className="service-offering-card-title">Strategy</h3>
              <p className="service-offering-card-content">
                Next, our strategists develop a comprehensive roadmap to
                leverage your business's strengths and integrate advanced IT
                infrastructure. We work closely with your team to ensure
                seamless adoption and alignment with your organizational goals.
              </p>
            </div>
          </div>

          <div className="service-offering-card">
            <div className="">
              <div className="service-offering-card-icon">
                <img src="/Performance.png" />
              </div>
              <h3 className="service-offering-card-title">Performance</h3>
              <p className="service-offering-card-content">
                The success of IT transformation hinges on effective
                collaboration between client and consultant. At Canvendor, we
                prioritize building strong client relationships to drive
                successful business outcomes and ensure a productive
                partnership.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container service-content-section">
        <div className="row">
          <div className="col-lg-10">
            <div className="">
              <span className="section-sub-title">Consulting</span>
              <h2 className="service-page-content-title">
                Software Consulting Services{" "}
              </h2>
              <p>
                {" "}
                At Canvendor, our Software Consulting team delivers expert
                analysis and tailored solutions to enhance your software
                systems. We conduct a comprehensive evaluation of your current
                software effectiveness and enterprise mobile strategy. Our
                in-depth assessment identifies key areas for improvement,
                provides actionable recommendations, and outlines a strategic
                plan to overcome any obstacles, ensuring your software
                infrastructure aligns with your business goals.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItSolution;
