import React from "react";
import NavComponent from "../../components/NavComponent";
import { useEffect, useState, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const CommunicationsAndInformation = () => {
  useEffect(() => {   
    window.scrollTo(0, 0); 
   }, []);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <NavComponent></NavComponent>

      <div class="indus-page-title-container commu-indus-bg-img">
        <div class="container indus-title-content-container">
          <h2 className="indus-page-title" data-aos="flip-down">
            Communication
          </h2>
          <p className="indus-page-tagline" data-aos="fade-up">
            {" "}
            Bridging gaps and fostering worldwide collaboration through
            effective communication.
          </p>
        </div>
      </div>

      <div class="container indus-content-section">
        <div class="row">
          <div class="col-lg-9">
            <span class="section-sub-title">Communication</span>
            <h2 class="indus-content-title">
              Telecom Digital Transformation | Advanced IT Solutions for the
              Telecom Industry
            </h2>
            <p>
              {" "}
              At Canvendor, we specialize in transforming telecommunications
              networks through technologies like edge computing, cloud
              solutions, OpenRAN, and 5G. Our deep understanding of the telecom
              value chain enables us to pinpoint outdated IT operations and
              replace them with agile, customer-centric solutions.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <p>
              Our telecom consulting services are designed to create agile,
              cost-effective, and secure networks with uninterrupted
              performance. By focusing on optimizing virtual core
              infrastructure, we ensure your network remains scalable and
              efficient. Our container-as-a-service infrastructure solutions
              offer flexible, efficient management of your hardware resources,
              driving enhanced performance and operational excellence.
            </p>
          </div>
        </div>
      </div>

      <div class="container indus-our-services-section">
        <h1 class="indus-content-title">Our Offerings</h1>
        <p className="mb-5">
          Experience superior communication solutions with our cutting-edge
          telecommunication services, crafted to bridge distances and enhance
          connectivity.
        </p>

        <div class="indus-service-grid">
          <div class="indus-service-item">
            <div class="tp-service__icon"></div>
            <h3 class="indus-service-item-title">
              Seamless Global Connectivity through Advanced Technology
            </h3>
            <p className="indus-service-item-para">
              Telecommunication involves transmitting voice, data, and
              multimedia across vast distances using various technologies,
              including wired and wireless networks, satellites, and optical
              fibers. This network of technologies connects the world, creating
              a comprehensive digital landscape that enhances communication
              globally.
            </p>
          </div>

          <div class="indus-service-item">
            <div class="tp-service__icon"></div>
            <h3 class="indus-service-item-title">
              The Advancement of Telecommunication Technologies
            </h3>
            <p className="indus-service-item-para">
              Telecommunication technology has progressed significantly, from
              the early telegraph systems to cutting-edge 5G networks. Key
              innovations such as mobile phones, the Internet, and cloud
              computing have transformed information exchange, offering faster,
              more reliable, and widely accessible communication to billions of
              users worldwide.
            </p>
          </div>

          <div class="indus-service-item">
            <div class="tp-service__icon"></div>
            <h3 class="indus-service-item-title">
              Telecommunication’s Impact on Society and Future Trends
            </h3>
            <p className="indus-service-item-para">
              Telecommunication is crucial to modern society, driving economic
              growth, improving education, enabling telemedicine, and fostering
              cultural exchange. With IoT (Internet of Things) and AI
              (Artificial Intelligence) integration into telecommunication
              systems, the future promises enhanced connectivity and new
              technological advancements.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunicationsAndInformation;
