import React, { useEffect, useState, useRef } from "react";
import HomeServices from "../components/home/HomeServices";
import NavComponent from "../components/NavComponent";
import { AiOutlineCloudServer } from "react-icons/ai";
import { GiArtificialIntelligence } from "react-icons/gi";
import { BsBuilding } from "react-icons/bs";
import { CgDatabase, CgWebsite } from "react-icons/cg";
import { DiAtlassian, DiDigitalOcean } from "react-icons/di";
import { Link } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const cardItem = [
  {
    img: `/aiml-solutions-service.jpg`,
    title: `AI/ML Solutions`,
    content: `At Canvendor, we understand the challenges of collecting, processing, and analyzing 
            vast amounts of valuable data. Our AI and ML solutions streamline these proces...`,
    link: `/services/aiml`,
  },
  {
    img: `/cloud-solutions-service.jpg`,
    title: `Cloud Solutions`,
    content: `At Canvendor, we understand that cloud solutions have transformed IT resource management, making it more efficient and flexible than ever. Embracing cutting-edge technol...`,
    link: `/services/cloudSolutions`,
  },
  {
    img: `/digital-solutions-service.jpg`,
    title: `Digital Transformation`,
    content: `At Canvendor, we drive digital transformation through tailored solutions that streamline operations and 
enhance customer interactions. Our services includ...`,
    link: `/services/digitalTransformation`,
  },
  {
    img: `/it-solutions-service.jpg`,
    title: `IT Consulting`,
    content: `At Canvendor, we bring together top IT experts to deliver comprehensive IT consulting and support solutions. 
Our team’s deep technical expertise and industr...`,
    link: `/services/itConsultingService`,
  },
  {
    img: `/enterprise-solutions-service.jpg`,
    title: `Enterprise services`,
    content: `At Canvendor, we understand that a superior customer experience (CX) is crucial for success. Our enterprise services are crafted to ensure that your CX is perfectly align...`,
    link: `/services/enterpriseService`,
  },
  {
    img: `/app-solutions-service.jpg`,
    title: `Application Engineering`,
    content: `At Canvendor, we deliver comprehensive app development services, covering every stage from design and integration to implementation and management. Our exper...`,
    link: `/services/webAppService`,
  },
];

const Services = () => {
  useEffect(() => {   
    window.scrollTo(0, 0); 
   }, []);
  return (
    <>
      <NavComponent></NavComponent>{" "}
      <section className=" services-page-head-bg  overflow-hidden">
        <div className="container service-bg-container">
          <div>
            <h1 className="service-title" data-aos="flip-down">
              Our Services
            </h1>
            <div className="page-direction" data-aos="fade-up">
              <span className="ps-0">
                <Link to="/">Home</Link>
              </span>
              <p>capabilities</p>
            </div>
          </div>
        </div>
      </section>
      <div className="services-page-card-section">
        <div className="container ">
          {/* <h1 className="services-card-section-title">Explore Our Services</h1> */}
          <div className="row">
            {cardItem.map((item) => (
              <div className="col-lg-4 ">
                <Link to={item.link}>
                  <div className="services-page-card">
                    <div className="services-page-card-content">
                      <h3>{item.title}</h3>
                      <p>{item.content}</p>

                      <h6 className="services-page-rm-btn">Read More</h6>
                    </div>
                    <div className="services-page-card-img">
                      <img className="service-img" src={item.img} alt="" />
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
