import React from 'react'

const Industries = () => {
  return (
   <>
   

   
   </>
  )
}

export default Industries