import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const Insights = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <div className="container-fluid insights-section-container">
        <div className="container">
          <h5 className="section-sub-title" data-aos="fade-up">Resources</h5>
          <h1 className="insights-title mb-5" data-aos="fade-up">Resources For You</h1>
          <div className="row insights-items-container">
            <div className="col-xxl-5 col-lg-6 mb-4">
              <Link to="/insights#SingleService">
                <div className="insights-featured-item" data-aos="fade-up">
                  <div className="insights-featured-img-container">
                    <img
                      className="insights-img"
                      src="Healthcare Revolution.jpg"
                    />
                    <div className="insights-featured-content">
                      <h3 className="insights-category">Cloud</h3>
                      <h2 className="insights-item-title">
                        Navigating the Digital Healthcare Revolution
                      </h2>

                      <p className="insights-know-btn">
                        Know more{" "}
                        
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xxl-7 col-lg-6 insights-content-grid">
              <Link to="/insights#SingleService">
                <div className="insights-item insights-item-2" data-aos="fade-up">
                  <div className="insights-img-container">
                    <img className="insights-img" src="data security.jpg" />
                  </div>
                  <div className="insights-content">
                    <h3 className="insights-category">Cloud</h3>
                    <h2 className="insights-item-title">
                      Understand the critical aspects of data security
                    </h2>

                    <p className="insights-know-btn">
                      Know more{" "}
                      
                    </p>
                  </div>
                </div>
              </Link>

              <Link to="/insights#SingleService">
                <div className="insights-item" data-aos="fade-up">
                  <div className="insights-img-container">
                    <img className="insights-img" src="complement EMRs.jpg" />
                  </div>

                  <div className="insights-content">
                    <h3 className="insights-category">Cloud</h3>
                    <h2 className="insights-item-title">
                      Explore technologies that complement EMRs
                    </h2>

                    <p className="insights-know-btn">
                      Know more{" "}
                     
                    </p>
                  </div>
                </div>
              </Link>

              <Link to="/insights#SingleService">
                <div className="insights-item insights-item-2" data-aos="fade-up">
                  <div className="insights-img-container">
                    <img
                      className="insights-img"
                      src="healthcare professionals.jpg"
                    />
                  </div>
                  <div className="insights-content">
                    <h3 className="insights-category">Cloud</h3>
                    <h2 className="insights-item-title">
                      Hear from industry experts, healthcare professionals
                    </h2>

                    <p className="insights-know-btn">
                      Know more{" "}
                      
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {
        // <div className="tp-blog-area pt-130 bg-white">
        //   <div className="container">
        //     <div className="row">
        //       <div className="col-12">
        //         <div className="tp-section-wrapper text-center pb-60">
        //           <span className="tp-section-subtitle mb-25">
        //             Our Latest News & Blog
        //           </span>
        //           <h2 className="tp-section-title">
        //             Stay updated with our <br />
        //             Insights
        //           </h2>
        //         </div>
        //       </div>
        //     </div>
        //     <div className="row">
        //       <div className="col-xl-6">
        //         <div className="tp-blog">
        //           <div className="tp-blog__thumb">
        //             <img
        //               src={"../assets/img/blog/blog-3.jpg"}
        //               style={{ maxWidth: "100%", height: "auto" }}
        //             ></img>
        //           </div>
        //           <h3 className="tp-blog__title tp-large-title">
        //             <a> Navigating the Digital Healthcare Revolution</a>
        //           </h3>
        //         </div>
        //       </div>
        //       <div className="col-xl-6">
        //         <div className="tp-blog-list">
        //           <div className="tp-blog-list__item d-flex pl-30 pb-20">
        //             <div className="tp-blog-list__item-thum w-img">
        //               <img
        //                 src="../assets/images/blog/blog-1.jpg"
        //                 style={{ maxWidth: "100%", height: "auto" }}
        //                 width={260}
        //               ></img>
        //             </div>
        //             <div className="tp-blog-list__item-details  pb-30">
        //               <div className="list-blog-meta">
        //                 <h3 className="list-blog-title pt-15 pb-10 d-inline-block">
        //                   <a>
        //                     {" "}
        //                     Understand the critical aspects of data security{" "}
        //                   </a>
        //                 </h3>
        //                 <Link to="/insights#SingleService">
        //                   {" "}
        //                   <a className="tp-btn-sm-black">
        //                     <img src="../arrowwithcircle.svg"></img>
        //                     <span className="" style={{ marginLeft: "15px" }}>
        //                       View More
        //                     </span>
        //                   </a>
        //                 </Link>
        //               </div>
        //             </div>
        //           </div>
        //           <div className="tp-blog-list__item d-flex pl-30 pb-20">
        //             <div className="tp-blog-list__item-thum w-img">
        //               <img
        //                 src="../assets/img/blog/blog-2.jpg"
        //                 style={{ maxWidth: "100%", height: "auto" }}
        //                 width={257}
        //               ></img>
        //             </div>
        //             <div className="tp-blog-list__item-details pb-30">
        //               <div className="list-blog-meta">
        //                 <h3 className="list-blog-title pt-15 pb-10 d-inline-block">
        //                   <a>Explore technologies that complement EMRs</a>
        //                 </h3>
        //                 <Link to="/insights#SingleService1">
        //                   {" "}
        //                   <a className="tp-btn-sm-black">
        //                     <img src="../arrowwithcircle.svg"></img>
        //                     <span className="" style={{ marginLeft: "15px" }}>
        //                       View More
        //                     </span>
        //                   </a>
        //                 </Link>
        //               </div>
        //             </div>
        //           </div>
        //           <div className="tp-blog-list__item d-flex pl-30 pb-20">
        //             <div className="tp-blog-list__item-thum w-img">
        //               <img
        //                 src="../assets/img/blog/blog-2.jpg"
        //                 style={{ maxWidth: "100%", height: "auto" }}
        //                 width={305}
        //               ></img>
        //             </div>
        //             <div className="tp-blog-list__item-details  pb-30">
        //               <div className="list-blog-meta">
        //                 <h3 className="list-blog-title pt-15 pb-10 d-inline-block">
        //                   <a>
        //                     Hear from industry experts, healthcare professionals
        //                   </a>
        //                 </h3>
        //                 <Link to="/insights">
        //                   {" "}
        //                   <a className="tp-btn-sm-black">
        //                     <img src="../arrowwithcircle.svg"></img>
        //                     <span className="" style={{ marginLeft: "15px" }}>
        //                       View More
        //                     </span>
        //                   </a>
        //                 </Link>
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      }
      <div className="why-canvendor-title-bg">
        <h1 className="why-canvendor-title" data-aos="fade-up">Why Canvendor?</h1>
      </div>
      <div className="container why-canvendor-section-container">
        <div className="why-canvendor-grid">
          <div className="why-canvendor-items">
            {/* <p className="why-canvendor-content">
              With operations spanning six key global locations and
              strategically positioned near-shore centers in the US, Mexico,
              Canada, UK, and UAE, we ensure seamless collaboration across
              borders.
            </p> */}
            <div className="why-canvendor-count">
              <h4>6</h4>
              <p> Global Presence</p>
            </div>
          </div>

          <div className="why-canvendor-items">
            {/* <p className="why-canvendor-content">
              Over 80% of our revenue stems from recurring orders, a testament
              to our unwavering commitment to delivering unparalleled customer
              satisfaction.
            </p> */}
            <div className="why-canvendor-count">
              <h4>Over 80%</h4>
              <p> Commitment to Satisfaction</p>
            </div>
          </div>

          <div className="why-canvendor-items">
            {/* <p className="why-canvendor-content">
              We specialize in establishing state-of-the-art Offshore
              Development Centers, empowering organizations with bespoke
              solutions tailored to their unique needs.
            </p> */}
            <div className="why-canvendor-count">
              <h4>-</h4>
              <p> Pioneers in Offshore Excellence</p>
            </div>
          </div>

          <div className="why-canvendor-items">
            {/* <p className="why-canvendor-content">
              Canvendor has delivered over 200 projects across healthcare,
              finance, retail, and technology sectors, demonstrating our
              expertise in diverse industries.
            </p> */}
            <div className="why-canvendor-count">
              <h4>Over 200+</h4>
              <p>Extensive Project Portfolio</p>
            </div>
          </div>
          <div className="why-canvendor-items">
            {/* <p className="why-canvendor-content">
              With operations spanning six key global locations and
              strategically positioned near-shore centers in the US, Mexico,
              Canada, UK, and UAE, we ensure seamless collaboration across
              borders.
            </p> */}
            <div className="why-canvendor-count">
              <h4>6</h4>
              <p> Global Presence</p>
            </div>
          </div>

          <div className="why-canvendor-items">
            {/* <p className="why-canvendor-content">
              Over 80% of our revenue stems from recurring orders, a testament
              to our unwavering commitment to delivering unparalleled customer
              satisfaction.
            </p> */}
            <div className="why-canvendor-count">
              <h4>Over 80%</h4>
              <p> Commitment to Satisfaction</p>
            </div>
          </div>

          <div className="why-canvendor-items">
            {/* <p className="why-canvendor-content">
              We specialize in establishing state-of-the-art Offshore
              Development Centers, empowering organizations with bespoke
              solutions tailored to their unique needs.
            </p> */}
            <div className="why-canvendor-count">
              <h4>-</h4>
              <p> Pioneers in Offshore Excellence</p>
            </div>
          </div>

          <div className="why-canvendor-items">
            {/* <p className="why-canvendor-content">
              Canvendor has delivered over 200 projects across healthcare,
              finance, retail, and technology sectors, demonstrating our
              expertise in diverse industries.
            </p> */}
            <div className="why-canvendor-count">
              <h4>Over 200+</h4>
              <p>Extensive Project Portfolio</p>
            </div>
          </div>
        </div>
      </div>

      {/* <section id="recent-blog-posts" className="recent-blog-posts pt-5 pb-5" style={{position:'relative',zIndex:'2'}}>
      <div class="container" data-aos="fade-up">

    
    
  <div class="section-header pb-3" id='blognew'>
        <h2 className='text-white pb-3'>INSIGHTS</h2>
    
      </div>

      <div class="row gy-5 gx-3">

        <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
          <div class="post-item position-relative h-100">

            <div class="post-img position-relative overflow-hidden">
              <img src={'http://www.canvendor.com/assets/images/blog/blog-1.jpg'} class="img-fluid" alt=""/>
              <span class="post-date px-2">December 12</span>
            </div>

            <div class="post-content d-flex flex-column">

              <h3 class="post-title px-2">Eum ad dolor et. Autem aut fugiat debitis</h3>

              <div class="meta d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <i class="bi bi-person"></i> <span class="ps-2">Julia Parker</span>
                </div>
                <span class="px-3 text-black-50">/</span>
                <div class="d-flex align-items-center">
                  <i class="bi bi-folder2"></i> <span class="ps-2">Politics</span>
                </div>
              </div>

              <hr/>

              <a href="blog-details.html" class="readmore stretched-link px-2 pb-2" ><span>Read More</span><i class="bi bi-arrow-right"></i></a>

            </div>

          </div>
        </div>

        <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
          <div class="post-item position-relative h-100">

            <div class="post-img position-relative overflow-hidden">
              <img src={'http://www.canvendor.com/assets/img/blog/blog-2.jpg'} class="img-fluid" alt=""/>
              <span class="post-date px-2">July 17</span>
            </div>

            <div class="post-content d-flex flex-column">

              <h3 class="post-title px-2">Et repellendus molestiae qui est sed omnis</h3>

              <div class="meta d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <i class="bi bi-person"></i> <span class="ps-2">Mario Douglas</span>
                </div>
                <span class="px-3 text-black-50">/</span>
                <div class="d-flex align-items-center">
                  <i class="bi bi-folder2"></i> <span class="ps-2">Sports</span>
                </div>
              </div>

              <hr></hr>

              <a href="blog-details.html" class="readmore stretched-link px-2 pb-2"><span>Read More</span><i class="bi bi-arrow-right"></i></a>

            </div>

          </div>
        </div>

        <div class="col-xl-4 col-md-6">
          <div class="post-item position-relative h-100" data-aos="fade-up" data-aos-delay="300">

            <div class="post-img position-relative overflow-hidden">
              <img  src={'http://www.canvendor.com/assets/img/blog/blog-3.jpg'} class="img-fluid" alt=""/>
              <span class="post-date px-2">September 05</span>
            </div>

            <div class="post-content d-flex flex-column">

              <h3 class="post-title px-2">Quia assumenda est et veritati tirana ploder</h3>

              <div class="meta d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <i class="bi bi-person"></i> <span class="ps-2">Lisa Hunter</span>
                </div>
                <span class="px-3 text-black-50">/</span>
                <div class="d-flex align-items-center">
                  <i class="bi bi-folder2"></i> <span class="ps-2">Economics</span>
                </div>
              </div>

              <hr/>

              <a href="blog-details.html" class="readmore stretched-link px-2 pb-2"><span>Read More</span><i class="bi bi-arrow-right"></i></a>

            </div>

          </div>
        </div>

      </div>

      </div>
    </section>



 */}
    </>
  );
};

export default Insights;
