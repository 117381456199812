import React from 'react'
import { useState } from 'react';
import Swal from 'sweetalert2';
import './Applyingnew.css'

function Applyingnew() {
       



  return (
    <>
        <div className='container mt-5 pt-5'>
    <div className='personalDetail form-section'>
<h4 className='mb-5'>Explore Opportunities</h4>


    </div>

    </div>
    
    </>
  )
}

export default Applyingnew