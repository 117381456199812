import React from "react";
import NavComponent from "../../components/NavComponent";
import "../services/service-css/style.css";
import { Link } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const Enterprise = () => {
  useEffect(() => {   
    window.scrollTo(0, 0); 
   }, []);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <NavComponent></NavComponent>
      <section className="enterprise-services-page-head-bg overflow-hidden">
        <div className="container service-bg-container ">
          <div>
            <h1 className="service-inner-page-title" data-aos="flip-down">
              Enterprise Solutions
            </h1>
            <div className="page-direction" data-aos="fade-up">
              <span className="ps-0">
                <Link to="/">Home</Link>
              </span>
              <span>
                <Link to="/services">capabilities</Link>
              </span>
              <p>Enterprise solutions</p>
            </div>
          </div>
          <img
            className="enterprise-img"
            src="/enterprise-services-page-img.png"
            data-aos="fade-left"
            data-aos-duration="3000"
          />
        </div>
      </section>

      <div className="container service-content-section">
        <div className="row">
          <div className="col-lg-10">
            <div className="">
              <span className="section-sub-title">
                Our misson is grow your Business
              </span>
              <h2 className="service-page-content-title">
                Enterprise Services{" "}
              </h2>
              <p>
                {" "}
                At Canvendor, we understand that a superior customer experience
                (CX) is crucial for success. Our enterprise services are crafted
                to ensure that your CX is perfectly aligned with your
                organization's value chain. By strategically differentiating how
                you engage with clients, employees, and partners, we help you
                create impactful, efficient interactions.
              </p>

              {/* <p>Canvendor simplifies and modernizes the existing landscape and helps our customers stay ahead in the digital economy.The enterprise application of any organization is at the core of its IT structure. Canvendor helps digitally transform multiple processes in an organization including CX, CRM, HCM, SCMM, and ERP </p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid Enterprise-our-offerings">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h1 className="service-page-offering-title">Our Offerings</h1>
              <p className="service-page-offering-para">
                Our diverse solutions are engineered to enhance operational
                excellence and deliver measurable business impact.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container service-card-margin">
        <div className="service-offering-section">
          <div className="service-offering-card">
            <div className="">
              <div className="service-offering-card-icon">
                <img src="/Crafting a Persuasive Customer Experience.png" />
              </div>
              <h3 className="service-offering-card-title">
                Crafting a Persuasive Customer Experience (CX)
              </h3>
              <p className="service-offering-card-content">
                At Canvendor, our agile team excels across key cloud platforms,
                establishing us as a leading digital transformation partner. We
                specialize in cloud infrastructure modernization and maintenance
                using cutting-edge technologies, expert insights, and top-tier
                engineering. Our solutions drive impactful industry results and
                enhance user engagement.
              </p>
            </div>
          </div>
          <div className="service-offering-card">
            <div className="">
              <div className="service-offering-card-icon">
              <img src="/Mapping Milestones for Customer Journey Transformation.png" />
              </div>
              <h3 className="service-offering-card-title">
                Mapping Milestones for Customer Journey Transformation
              </h3>
              <p className="service-offering-card-content">
                We focus on pivotal moments in the customer service journey,
                helping businesses set clear goals with measurable outcomes such
                as cost reductions, improved satisfaction scores, and increased
                customer value. By optimizing software, platforms, and data,
                Canvendor delivers tangible business value and exceptional
                customer service. Additionally, our digital HR offerings support
                companies in achieving their strategic objectives.
              </p>
            </div>
          </div>
          {/* <div className='col-xl-4 col-md-6 tp'>
		<div className='tp-service2 text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
		
			</div>
			<h3 className='tp-service__title text-left  pt-40 pb-25'>
				<a>Company Driven by AI</a>
			</h3>
			<p>
            Leaders that are at the top of their game are using AI to gain insight into what is happening and how it is happening. This enables them to take proactive actions to stay ahead. AI enables you to look at all available resources. 
			</p>
		
		</div>
	</div> */}
        </div>
      </div>

      <div className="container service-content-section">
        <div className="row">
          <div className="col-lg-10">
            <div className="">
              <span className="section-sub-title">Consulting</span>
              <h2 className="service-page-content-title">
                Essential Toolkit for Successful HR Digital Transformation{" "}
              </h2>
              <p>
                {" "}
                As organizational processes undergo significant transformation,
                it's crucial for HR practices to adapt accordingly. The
                traditional methods are giving way to modern approaches as the
                workforce changes, mergers and acquisitions become more
                frequent, and regulations continuously evolve. Embracing the
                right digital transformation toolkit is essential for navigating
                these shifts effectively.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Enterprise;
