import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import NavComponent from "../components/NavComponent";
import { FaMapMarkerAlt, FaPhoneVolume } from "react-icons/fa";
import { BsEnvelopeFill } from "react-icons/bs";
import axios from "axios";

const Contact = () => {
  useEffect(() => {   
    window.scrollTo(0, 0); 
   }, []);
  const [formData, setFormData] = useState({
    uname: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://canvendormail.azurewebsites.net:443/api/canvendormail/triggers/When_a_HTTP_request_is_received/invoke?api-version=2022-05-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=h_hWOFMDinSqYNp8ci5IPaw2qhnSzsCPrjazTDiMnHQ",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 || response.status === 202) {
        Swal.fire({
          title: "Successfully Submitted",
          text: "",
          icon: "success",
          confirmButtonText: "Ok",
        });
        setFormData({
          uname: "",
          email: "",
          subject: "",
          message: "",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Failed to send Email",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Failed to send Email",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <>
      <NavComponent />

      {/* <section className="contact-us pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="contact-us__item mb-40">
                <div className="contact-us__item-header  mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                  <div className="icon me-2 color-primary">
                    {" "}
                    <FaMapMarkerAlt className="fal fa-map-marker-alt" />
                  </div>
                  <h5 className="title color-d_black contactloc">India</h5>
                </div>
                <div className="contact-us__item-body font-la ms-3 ps">
                  <h5 className="address-type">Nagercoil</h5>
                  Canvendor Software Solutions pvt. 19/3 PRK Square, Court Road
                  <br></br>
                  Nagercoil - 629001, Tamilnadu.
                </div>
                <hr></hr>
                <div className="contact-us__item-body font-la ms-3 mt-3">
                  <h5 className="address-type">Chennai</h5>
                  M63,Cactus Corporate, Suite#173,<br></br> 7th Floor, Block B,
                  Tecci Park,<br></br> OMR, Sholinganallur<br></br>
                  Chennai - 600119, Tamilnadu.
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="contact-us__item mb-40">
                    <div className="contact-us__item-header  mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div className="icon me-2 color-primary">
                        {" "}
                        <FaMapMarkerAlt className="fal fa-map-marker-alt" />
                      </div>
                      <h5 className="title color-d_black contactloc"> USA</h5>
                    </div>
                    <div className="contact-us__item-body font-la ms-3">
                      7567 Amador Valley Blvd,<br></br>
                      Suite 106, Dublin,<br></br>
                      CA - 94568.<br></br>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="contact-us__item mb-40">
                    <div className="contact-us__item-header  mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div className="icon me-2 color-primary">
                        {" "}
                        <FaMapMarkerAlt className="fal fa-map-marker-alt" />
                      </div>
                      <h5 className="title color-d_black contactloc">
                        {" "}
                        Canada
                      </h5>
                    </div>
                    <div className="contact-us__item-body font-la ms-3">
                      Canvendor Software Corp <br></br>
                      1103-11871 Horseshoe Way <br></br>
                      Vancouver <br></br>
                      British Columbia V7A 5H5.<br></br>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="contact-us__item mb-40">
                    <div className="contact-us__item-header  mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div className="icon me-2 color-primary">
                        {" "}
                        <FaMapMarkerAlt className="fal fa-map-marker-alt" />
                      </div>
                      <h5 className="title color-d_black contactloc">
                        United Kingdom
                      </h5>
                    </div>
                    <div className="contact-us__item-body font-la ms-3 ps">
                      {" "}
                      Canvendor Limited <br></br>2 Woodberry Grove <br></br>
                      London N12 0DR.<br></br>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="contact-us__item mb-40">
                    <div className="contact-us__item-header  mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div className="icon me-2 color-primary">
                        {" "}
                        <FaMapMarkerAlt className="fal fa-map-marker-alt" />
                      </div>
                      <h5 className="title color-d_black contactloc"> UAE</h5>
                    </div>
                    <div className="contact-us__item-body font-la ms-3">
                      CANVENDOR CORP LLC FZ.,<br></br> M Floor, The Meydan
                      Hotel, <br></br> Al Sheba,<br></br> Dubai.
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="contact-us__item mb-40">
                    <div className="contact-us__item-header  mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div className="icon me-2 color-primary">
                        {" "}
                        <FaMapMarkerAlt className="fal fa-map-marker-alt" />
                      </div>
                      <h5 className="title color-d_black contactloc">
                        Singapore
                      </h5>
                    </div>
                    <div className="contact-us__item-body font-la ms-3">
                      CANVENDOR PTE.LTD.
                      <br />9 Raffles Place
                      <br />
                      #29-05 Republic Plaza,
                      <br /> Singapore(048619).
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div className="contact-page-bg">
        <img src="/contact-bg-map.png" className="contact-bg-map" />
        <div className="container">
          <div className="row contact-page-container">
            <div className="col-lg-7 contact-form-container">
              <h2 className="contact-page-title">
                Get In <span>Touch</span>
              </h2>
              <p className="contact-page-tagline">
                Need support with our products, have a query about our services,
                or just want to say hello, our dedicated team is eager to
                connect with you.
              </p>
              <form
                id="contact-form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <div className="name-email">
                  <input
                    type="text"
                    id="uname"
                    placeholder="Enter your name"
                    required
                    name="uname"
                    value={formData.uname}
                    onChange={handleInputChange}
                    maxLength={20}
                  />
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  required
                  maxLength={50}
                />
                <textarea
                  placeholder="Enter your message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                />

                <div className="disclaimer-privacy">
                  <div className="disclaimer text-start">
                    <h2 className="text-uppercase">Disclaimer</h2>
                    <p>
                      "By providing my phone number to “Canvendor”, I agree and
                      acknowledge that “Canvendor” may send text messages to my
                      wireless phone number for any purpose. Message and data
                      rates may apply. Message frequency will vary, and you will
                      be able to Opt-out by replying “STOP”. For more
                      information on how your data will be handled please See
                      the{" "}
                      <a href="https://www.canvendor.com/privacyPolicy">
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>

                <div className="text-center">
                  <button
                    className="tp-btn-border contact-mail-btn"
                    type="submit"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>

            <div className="col-lg-5 location-items-container">
              <h1 className="locations">Our Global Presence</h1>
              <div className="contact-locations">
                <div className="contact-location-item">
                  <h1 className="contact-location-continent">APAC</h1>
                  <div className="country-container">
                    <h2 className="contact-location-country">India</h2>

                    <div className="mb-4">
                      <h2 className="contact-location-city">Nagercoil</h2>
                      <p className="contact-location-address">
                        Canvendor Software Solutions pvt. 19/3 PRK Square, Court
                        Road Nagercoil - 629001, Tamilnadu.
                      </p>
                      <a target="_blank" className="location-direction" href="https://maps.app.goo.gl/Kiz1Yt5uPvefMrFM6" ><span><img src="/location direction.png"/></span>Get Directions</a>
                    </div>

                    <div className="mb-0">
                      <h2 className="contact-location-city">Chennai</h2>
                      <p className="contact-location-address">
                        M63,Cactus Corporate, Suite#173, 7th Floor, Block B,
                        Tecci Park, OMR, Sholinganallur Chennai - 600119,
                        Tamilnadu.
                      </p>
                    </div>
                  </div>

                  <div className="country-container mb-0">
                    <h2 className="contact-location-country">Singapore</h2>

                    <div className="mb-3">
                      <h2 className="contact-location-city">Singapore</h2>
                      <p className="contact-location-address">
                        CANVENDOR PTE.LTD. 9 Raffles Place #29-05 Republic
                        Plaza, Singapore(048619).
                      </p>
                    </div>
                  </div>
                </div>

                <div className="contact-location-item">
                  <h1 className="contact-location-continent">Americas</h1>

                  <div className="country-container">
                    <h2 className="contact-location-country">USA</h2>

                    <div className="mb-3">
                      <h2 className="contact-location-city">Dublin</h2>
                      <p className="contact-location-address">
                        7567 Amador Valley Blvd, Suite 106, Dublin, CA - 94568.
                      </p>
                    </div>
                  </div>
                  <div className="country-container mb-0">
                    <h2 className="contact-location-country">Canada</h2>

                    <div className="mb-3">
                      <h2 className="contact-location-city">
                        British Columbia
                      </h2>
                      <p className="contact-location-address">
                        Canvendor Software Corp 1103-11871 Horseshoe Way
                        Vancouver British Columbia V7A 5H5.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="contact-location-item">
                  <h1 className="contact-location-continent">Europe</h1>
                  <div className="country-container mb-0">
                    <h2 className="contact-location-country">United Kingdom</h2>
                    <div className="mb-3">
                      <h2 className="contact-location-city">London</h2>
                      <p className="contact-location-address">
                        Canvendor Limited 2 Woodberry Grove London N12 0DR.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="contact-location-item mb-0">
                  <h1 className="contact-location-continent">MENA</h1>
                  <div className="country-container mb-0">
                    <h2 className="contact-location-country">UAE</h2>

                    <div className="mb-3">
                      <h2 className="contact-location-city">Dubai</h2>
                      <p className="contact-location-address">
                        CANVENDOR CORP LLC FZ., M Floor, The Meydan Hotel, Al
                        Sheba, Dubai.
                      </p>
                    </div>
                  </div>
                </div>

                

              
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
