import React from "react";
import "../components/css/about.css";
import NavComponent from "../components/NavComponent";
import WhyCanvendor from "./about/WhyCanvendor";
import { useEffect, useState, useCallback } from "react";

import { Link } from "react-router-dom";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavComponent></NavComponent>

      {/* <div className="company-skill pt-xs-80 pb-xs-80 pt-sm-100 pt-md-100 pt-120 pb-100 overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="company-skill__content">
                <span className="sub-title d-block fw-500  text-uppercase mb-sm-10 mb-xs-5 mb-md-15 mb-20">
                  About Us
                </span>
                <h2 className="title color-pd_black mb-25 mb-xs-10 mb-sm-15">
                  Our Company Provide <span>High Quality Idea</span>
                </h2>
                <div className="description font-la">
                  <p>
                    Canvendor was founded in the year 2016 with the aim to
                    partner with our clients to address their IT needs and make
                    sure they stay ahead in taking strategic business decisions.
                    Canvendor, as a software development company creates value
                    for our clients by developing industry-specific systems and
                    we serve a plethora of industry verticals including
                    hospitals, banking, automobile, financial services etc (need
                    client details). And to aid in accelerating clients’
                    journeys toward going digital, we bring our digital
                    expertise industry know-how together into horizontal
                    offerings and solutions that give momentum to the most
                    essential leaps that today’s technology makes possible for
                    businesses of today. {" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="company-skill__media-wrapper d-flex flex-column mt-lg-60 mt-md-50 mt-sm-45 mt-xs-40 align-items-center">
                <div className="company-skill__media">
                  <img
                    src="company-skill-meida.png"
                    className="img-fluid"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container about-page-title-bg">
        <div className="row">
          <div className="col-lg-7 about-page-content">
            <h3 className="section-sub-title">About Us</h3>
            <h1 className="about-page-title">
              Our Company
              <br /> Provide High Quality Idea
            </h1>
            <p>
              Canvendor was founded in the year 2016 with the aim to partner
              with our clients to address their IT needs and make sure they stay
              ahead in taking strategic business decisions. Canvendor, as a
              software development company creates value for our clients by
              developing industry-specific systems and we serve a plethora of
              industry verticals including hospitals, banking, automobile,
              financial services etc (need client details). And to aid in
              accelerating clients’ journeys toward going digital, we bring our
              digital expertise industry know-how together into horizontal
              offerings and solutions that give momentum to the most essential
              leaps that today’s technology makes possible for businesses of
              today. 
            </p>
          </div>
          <div className="col-lg-5 about-title-img">
            <img src="/about title img.jpg" />
          </div>
        </div>
      </div>

      <div className="about-mission-bg">
        <div className="container">
          <div className="about-mission-container">
            <h1>
              Canvendor co-builds a shared vision with our customers and focuses
              on accomplishing their business end goal.
            </h1>
            <Link to="/contactUs">
              <div className="about-lets-talk">
                <p>Let’s Talk</p>
                <h2>Get in Touch</h2>
                <img className="arrow-top-w" src="/arrow-top-w.png" />
                <img className="arrow-top-b" src="/arrow-top-b.png" />
              </div>
            </Link>
          </div>
        </div>
      </div>
      {/* <section className="pt-5 pb-5" style={{ backgroundColor: "white" }}>
        <div className="container pb-3 pt-5">
          <div className="row">
            <div className="col-lg-8">
              <div className="tp-section-wrapper">
                <span className="tp-section-subtitle tp-subtitle-before mb-3">
                  OUR MISSION IS TO DELIVER SUCCESS
                </span>
                <h2 className="tp-section-title1 tp-section-title-large">
                  Canvendor co-builds a shared vision with our customers and
                  focuses on accomplishing their business end goal.{" "}
                </h2>
              </div>
            </div>
            <div className="col-lg-4 d-flex justify-content-lg-end">
              <Link to="/contactUs">
                <a className="getintouch">
                  <div className="tp-theme-btn d-flex align-items-center white-bg">
                    <div className="tp-btn-inner">
                      <p>Let’t Talk</p>
                      <b>Get in Touch</b>{" "}
                      <img src="../Vector1.png" className="pointerimg"></img>
                    </div>
                  </div>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </section> */}

      <section
        class="about-details-content-section"
        style={{ position: "relative" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-5 about-details-content-img">
              <img src="about.png" alt />
            </div>
            <div class="col-lg-7">
              <div class="about-text">
                <h2 className="about-details-content-title">
                  We harness the immense capabilities of the digital world{" "}
                </h2>
                <p>
                  Canvendor fuels businesses with modern engineering. We are one
                  of the frontrunners in bringing about digital transformations
                  for our clients from across the globe. As a company, we are
                  adept at helping our clients with the modernization of
                  existing tech, reconceive processes, and transform experiences
                  so that they can stay ahead in their game in an ever-evolving
                  world.There is no better time than today to collaborate and
                  harness the best of the tech that is on offer for all of us to
                  grab to be a frontrunner. By serving our clients we and
                  meeting their needs we want to serve the communities our
                  clients are serving.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
