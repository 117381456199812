import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useLocation } from "react-router-dom";
import "./css/banner.css";
const NavComponent = () => {
  return (
    <>
      <div className="container-fluid navbar-container">
        <Navbar
          expand="lg"
          fixed="top"
          /*  style={{zIndex:'2',top:'0px',backgroundColor:'rgb(31,33,72)'}}*/
        >
          <Container>
            <div className="second-nav-brand">
              <img
                className="img-fluid"
                src={"../assets/images/canvendor_blue_transparent_logo.png"}
                width={150}
                height={30}
              />
            </div>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              style={{
                border: "2px solid white",
                backgroundColor: "rgba(255, 255, 255, 0.527)",
              }}
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link>
                  <Link to="/">Home</Link>{" "}
                </Nav.Link>

                <li className="dropdown me-0 dropdown-link">
                  <Link to="/services" className="nav-link ">
                    Capabilities
                    <i
                      class="fa fa-caret-down"
                      aria-hidden="true"
                      style={{ fontSize: 16 }}
                    ></i>
                  </Link>
                  <ul>
                    <li>
                      <Link to="/services/aiml">AI/ML SOLUTIONS</Link>
                    </li>
                    <li>
                      <Link to="/services/webAppService">
                        APPLICATION ENGINEERING
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/cloudSolutions">CLOUD SOLUTIONS</Link>
                    </li>
                    <li>
                      <Link to="/services/digitalTransformation">
                        DIGITAL TRANSFORMATION
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/enterpriseService">
                        ENTERPRISES SOLUTIONS
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/itConsultingService">
                        IT CONSULTING
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown me-0 dropdown-link">
                  <Link to="" className="nav-link scrollto">
                    Industries
                    <i
                      class="fa fa-caret-down"
                      aria-hidden="true"
                      style={{ fontSize: 16 }}
                    ></i>
                  </Link>
                  <ul>
                    <li>
                      <Link to="/industries/automotive">AUTOMOTIVE</Link>
                    </li>
                    <li>
                      <Link to="/industries/banking&finance">BANKING</Link>
                    </li>
                    {/* <li>
                      <Link to="/industries/communications&information">
                        COMMUNICATION
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/industries/healthcare">HEALTHCARE</Link>
                    </li>
                    <li>
                      <Link to="/industries/insurance">INSURANCE</Link>
                    </li>
                    <li>
                      <Link to="/industries/manufacturing">MANUFACTURING</Link>
                    </li>
                  </ul>
                </li>
                {/* <Nav.Link ><Link to='' style={linkStyle}>Ecosystem</Link></Nav.Link> */}
                <Nav.Link>
                  <Link to="/insights#SingleService">Insights</Link>
                </Nav.Link>
                <Nav.Link>
                  <Link to="/about">About</Link>
                </Nav.Link>
                {/* <Nav.Link ><Link to='' style={linkStyle}>Resources</Link></Nav.Link> */}
                <Nav.Link>
                  {" "}
                  <Link to="/carrer">Careers</Link>
                </Nav.Link>
                <Nav.Link>
                  {" "}
                  <Link to="/contactUs">Contact</Link>
                </Nav.Link>
                {/* <Nav.Link><Link to='/enquiry'>Enquriy</Link></Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default NavComponent;
