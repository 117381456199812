import React, { useEffect, useState, useRef } from "react";
import HomeServices from "../components/home/HomeServices";
import NavComponent from "../components/NavComponent";
import { AiOutlineCloudServer } from "react-icons/ai";
import { GiArtificialIntelligence } from "react-icons/gi";
import { BsBuilding } from "react-icons/bs";
import { CgWebsite } from "react-icons/cg";
import { DiDigitalOcean } from "react-icons/di";
import { Link } from "react-router-dom";

const Policy = () => {
  useEffect(() => {   
    window.scrollTo(0, 0); 
   }, []);
  return (
    <>
      <NavComponent></NavComponent>{" "}
      <section className="page-bannernew pt-xs-60 pt-sm-80 overflow-hidden"></section>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-lg-6">
            <div className="heroPictureCardBox">
              <div className="heroPictureCardBoxTitle">
                <h1>Privacy Policy </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="termsofservice mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="article-text article-quote-text privacy-policy-container">
                <p>
                  Welcome to Canvendor Software Solutions! We value your privacy
                  and are committed to protecting your personal information.
                  This privacy policy explains how we collect, use, and protect
                  your data when you use our IT consulting services.
                </p>
                <h2>Information We Collect</h2>
                <p>
                  We may collect various types of information when you engage
                  with our IT consulting services:
                </p>
                <h2>Personal Information</h2>
                <p>
                  Name<br></br>
                  Email address<br></br>
                  Phone number<br></br>
                  Postal address<br></br>
                  Company name (if applicable)
                </p>
                <h4>Project Information</h4>
                <p>
                  Information related to the consulting project, including
                  project requirements, goals, and deliverables
                </p>
                <h4>Communication Data</h4>
                <p>
                  Records of your interactions with us, such as emails,
                  messages, and notes from consulting sessions
                </p>
                <h4>Usage Data</h4>
                <p>
                  Information about your interactions with our website,
                  including IP address, browser type, device information, and
                  cookies
                </p>

                <h2>How We Use Your Information</h2>
                <p>We use your information for the following purposes:</p>

                <h4>Providing Consulting Services</h4>
                <p>
                  Canvendor Software Solutions will not be liable for any
                  indirect, special, consequential, or incidental damages,
                  including, but not limited to, loss of data, profits, or
                  business interruptions.
                </p>
                <h4>Communication</h4>
                <p>
                  Sending project updates, progress reports, and important
                  announcements<br></br>
                  Responding to your inquiries and providing support
                </p>
                <h2>Legal Compliance</h2>
                <p>
                  Complying with applicable laws and regulations<br></br>
                  Protecting our legal rights
                </p>
                <h2>Data Sharing and Disclosure</h2>
                <p>
                  No mobile information will be shared with third
                  parties/affiliates for marketing/promotional purposes. All the
                  above categories exclude text messaging originator opt-in data
                  and consent; this information will not be shared with any
                  third parties.
                </p>
                <p>We may share your information with the following parties:</p>
                <h4>Service Providers</h4>
                <p>
                  Third-party service providers who assist us in delivering our
                  IT consulting services (e.g., cloud hosting providers, project
                  management tools
                </p>
                <h4>Legal Obligations</h4>
                <p>
                  In response to a legal request or to comply with applicable
                  laws and regulations
                </p>
                <h4>Business Partners</h4>
                <p>
                  When necessary for collaborative projects or partnerships
                  (only with your consent)
                </p>
                <h2>Cookies and Tracking Technologies</h2>
                <p>
                  We use cookies and similar tracking technologies to enhance
                  your experience on our website. By using our website, you
                  consent to the use of cookies as described in our [Cookie
                  Policy].
                </p>
                <h2>Data Security</h2>
                <p>
                  We implement industry-standard security measures to protect
                  your data from unauthorized access, disclosure, alteration,
                  and destruction. However, no method of transmission over the
                  internet or electronic storage is completely secure.
                </p>
                <h2>Changes to this Policy</h2>
                <p>
                  We may update this privacy policy from time to time. The most
                  current version will be posted on our website with the
                  effective date.
                </p>
                <h2>Contact Us</h2>
                <p>
                  If you have any questions or concerns about our privacy policy
                  or the way we handle your data, please contact us at
                  contact@canvendor.com.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Policy;
