import React from "react";
import NavComponent from "../../components/NavComponent";
import "../services/service-css/style.css";
import { Link } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const DigitalService = () => {
  useEffect(() => {   
    window.scrollTo(0, 0); 
   }, []);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <NavComponent></NavComponent>

      <section className="digital-services-page-head-bg overflow-hidden">
        <div className="container service-bg-container ">
          <div>
            <h1 className="service-inner-page-title" data-aos="flip-down">
              Digital Transformation
            </h1>
            <div className="page-direction" data-aos="fade-up">
              <span className="ps-0">
                <Link to="/">Home</Link>
              </span>
              <span>
                <Link to="/services">capabilities</Link>
              </span>
              <p>Digital Transformation</p>
            </div>
          </div>
          <img
            className="digital-img"
            src="/digital-services-page-img.jpg"
            data-aos="fade-left"
            data-aos-duration="3000"
          />
        </div>
      </section>

      <div className="container service-content-section">
        <div className="row">
          <div className="col-lg-10">
            <div className="">
              <span className="section-sub-title">
                Our misson is grow your Business
              </span>
              <h2 className="service-page-content-title">
                Digital Transformation Services{" "}
              </h2>
              <p>
                {" "}
                At Canvendor, we drive digital transformation through tailored
                solutions that streamline operations and enhance customer
                interactions. Our services include process optimization,
                technology upgrades, and innovative digital strategies. We focus
                on delivering measurable results, ensuring your business stays
                competitive and agile in the digital era. Partner with us to
                transform your business and achieve sustainable growth.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid digital-our-offerings">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h1 className="service-page-offering-title">Our Offerings</h1>
              <p className="service-page-offering-para">
                At Canvendor, we provide a range of services designed to enhance
                your business, from innovative UI/UX design to comprehensive
                data infrastructure and management solutions.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container service-card-margin">
        <div className="service-offering-section">
          <div className="service-offering-card">
            <div className="">
              <div className="service-offering-card-icon">
                <img src="/UIUX Design Ideation and Design.png" />
              </div>
              <h3 className="service-offering-card-title">
                UI/UX Design Ideation and Design
              </h3>
              <p className="service-offering-card-content">
                Our comprehensive UI/UX services create effective customer
                retention strategies, helping brands develop impactful,
                human-centered personas that engage and build relationships with
                your customers.
              </p>
            </div>
          </div>
          <div className="service-offering-card">
            <div className="">
              <div className="service-offering-card-icon">
                <img src="/Data infrastructure and management.png" />
              </div>
              <h3 className="service-offering-card-title">
                Data infrastructure and management
              </h3>
              <p className="service-offering-card-content">
                Transform your data into valuable, actionable insights to make
                better business decisions, create new revenue streams, and
                outpace the competition. Canvendor's dedicated development team
                possesses the expertise to achieve this.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container service-content-section">
        <div className="row">
          <div className="col-lg-10">
            <div className="">
              <span className="section-sub-title">Consulting</span>
              <h2 className="service-page-content-title">
                Digital Transformation Solutions{" "}
              </h2>
              <p>
                Canvendor’s dedicated team of experts excels in providing
                innovative IT transformation solutions that are tailored to meet
                your unique needs. With our state-of-the-art platforms and
                proven methodologies, we ensure seamless integration and
                exceptional performance, empowering your business to stay ahead.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='container'>
               <div className='row'>
               <div className='col-lg-9'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Consulting</span>
        <h2 className='tp-section-title tp-section-title-large'> 

        AI/ ML SOLUTIONS  </h2>
<p> At Canvendor, IT consulting is our forte. Our team is empowered to solve an array of business snags at any stage of digital transformation. The team is adept at eliminating gaps and building smooth-sailing roadmaps that give our customers a competitive advantage over their competition. </p>
    </div>
</div>
<div className='col-lg-3 d-flex justify-content-lg-end'>
    <img src='../robotic-automation.png' width={250}></img>
</div>
               </div>
                </div> */}
      {/* <section className='page-banner pt-xs-60 pt-sm-80 overflow-hidden'>
	<div className='container'>
		<div className='row align-items-center pt-5'>
			<div className='col-md-6 pt-5'>
				<div className='page-banner__content mb-xs-10 mb-sm-15 mb-md-15 mb-20'>
					<div className='transparent-text'>Services</div>
					<div className='page-title'><h1>Digital<span> Transformation</span>	</h1>
					</div>
				</div>
			</div>
			<div className='col-md-6 pt-5'>
				<div className='mt-xs-30 mt-sm-40'>
					<img src='../digt.jpg' width={400} className='img-fluid'></img>
				</div>
			</div>
		</div>
	</div>
</section>

<div className='company-skill pt-xs-80 pb-xs-80 pt-sm-100 pt-md-100 pt-120 pb-100 overflow-hidden'>

	<div className='container'>
		<div className='row'>
			<div className='col-xl-6'>
				<div className='company-skill__content'>
					
					<h2 className='title color-pd_black mb-25 mb-xs-10 mb-sm-15'>Digital transformation Services</h2>
					<div className='description font-la'><p> The pandemic has changed the way the world operates. The businesses stood the test of time only because of the strong IT infrastructure. IT leaders across the globe have been vocal about how IT has supported, workforces, customers, and industries with digital transformation.</p>
                        <p>The environment is now conducive to embracing intellectual, digitally enabled working models and strategies. 
Canvendor has a dedicated development team that has the proficiency, platforms, and aids to deliver flawless IT transformation services curated to stay ahead of ever-changing market needs.</p>   </div>
<h4 className='title mb-25 mb-xs-10 mb-sm-15'>App modernization services </h4>
					<div className='description font-la'><p>Our specialists evaluate your corporation’s tech structure, procedures, and edifices to both reinforce the organization’s short-term performance and preserve sustainable long-term upgrading. App modernization is the process of updating legacy software for the latest computing methodologies, including the newest languages, structures, and groundwork platforms.  Partner with Canvendor for a seamless transformation.  </p>
                  </div>
				</div>
			</div>
			<div className='col-xl-6'>
				<div className='company-skill__media-wrapper d-flex flex-column mt-lg-60 mt-md-50 mt-sm-45 mt-xs-40 align-items-center'>


					<div className='company-skill__media'>
						<img src='http://www.canvendor.com/assets/images/services/app-modirazation1.png' className='img-fluid'></img>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<section className='why-choose pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-110 overflow-hidden'>
    <div className='container'>
        <div className='row'>
            <div className='col-xl-6'>
                <div className='why-choose__media-wrapper d-flex flex-column'>
                    <div className='gallery-bar'></div>
                    <div className='why-choose__media'>
                        <img src='http://www.canvendor.com/assets/img/blog/blog-3.jpg' className='img-fluid'></img>
                    </div>
                    <div className='global-country text-center'></div>
                </div>
            </div>
            <div class="col-xl-6">
                    <div class="why-choose__content mt-lg-60 mt-md-50 mt-sm-40 mt-xs-35">
                        <div class="why-choose__text mb-40 mb-md-35 mb-sm-30 mb-xs-30">
                       
                            <h2 class="title color-pd_black">CANVENDOR’S OFFERINGS </h2>
                            
               
                        </div>
                        <h4 className='title  mb-25 mb-xs-10 mb-sm-15'>UI/UX Design Ideation and Design </h4>
					<div className='description font-la'><p>Our entire range of UI/UX services constructs effective customer preservation strategies and assists brands in creating influential, human-centered personalities that engross, stimulate, and give shape to relationships with your patrons. 
</p>

    
</div>
<h4 className='title  mb-25 mb-xs-10 mb-sm-15'>Data infrastructure and management  </h4>
					<div className='description font-la'><p>Convert your data into treasured, actionable perceptions to make better business choices, generate new income streams, and win from the opposition. 
</p>

    
</div>
                    </div>
                </div>
        </div>
    </div>
</section> */}
    </>
  );
};

export default DigitalService;
