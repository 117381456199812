import React from 'react'
import { Link } from 'react-router-dom'
import { Accordion } from 'react-bootstrap'
const WhyCanvendor = () => {
  return (
    <>
    


   <section id="whyabout" className="" style={{position:'relative',zIndex:'2',}}>
	<div className="container-fluid aos-init aos-animate" data-aos="fade-up">
		<div className="row">
	
			<div className="col-lg-5 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
				<div className="content">
				<h3>Why <strong>Canvendor</strong></h3>
					
					
					<p> Canvendor is focused on bringing in the latest levels of relevance to large,
               established organizations through the well-researched application of the newest technologies—we have the 
               proficiency and the capability to do it.</p>
				
				
				
				
				
				</div>
				<div className="accordion-list">
				<Accordion>
      <Accordion.Item eventKey="0" >
        <Accordion.Header> Client focussed culture</Accordion.Header>
        <Accordion.Body>
		It is of utmost importance for us to keep clients at the top of what we do. And we believe that is what sets us apart from our competition.
		 One of the measures of our client relationship is that over fifty percent of our revenue still comes from our old clients.
        </Accordion.Body>
      </Accordion.Item>
	  </Accordion>
	  <br/>
	  <Accordion>
	  <Accordion.Item eventKey="1">
        <Accordion.Header>Brilliant digital transformation skill <i className="bx bx-chevron-down icon-show"></i></Accordion.Header>
        <Accordion.Body>
		Our expertise as an IT consulting and software development agency comes from our constant application of data
		 and investment in the latest technology to derive better outcomes.
        </Accordion.Body>
      </Accordion.Item>
	  </Accordion>
	  <br/>
	  <Accordion>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Flexible and responsive engagement model </Accordion.Header>
        <Accordion.Body>
		Canvendor co-builds a shared vision with our customers and focuses on accomplishing their business end goal.
        </Accordion.Body>
      </Accordion.Item>
	  </Accordion>
	  <br/>
	  <Accordion>
	  <Accordion.Item eventKey="3">
        <Accordion.Header> Inherently industry-centric </Accordion.Header>
        <Accordion.Body>
		From the beginning, our industry-first approach to business hurdles has helped us see the unseen 
		and deliver uninterrupted innovation.
        </Accordion.Body>
      </Accordion.Item>
	  </Accordion>
	  <br/>
	  <Accordion>
	  <Accordion.Item eventKey="4">
        <Accordion.Header> A sound management track record  </Accordion.Header>
        <Accordion.Body>
		Product management and software development are our core competencies and we have a proven track record in the design and implementation of projects.
        </Accordion.Body>
      </Accordion.Item>
	  </Accordion>
	  
	  
				</div>
				
			</div>
			<div className="col-lg-7 align-items-stretch order-1 order-lg-2 img aos-init aos-animate" style={{backgroundImage:" url(&quot;assets/img/why-us.png&quot;);"}} data-aos="zoom-in" data-aos-delay="150">
				<img src={"http://www.canvendor.com/assets/images/about/meeting-g91d5039c9_1920.jpg"}  className="img-fluid rounded"/>
				{/* <div className='whyCan_image'></div> */}
				</div> 
		</div>
	</div>
</section>








    </>
  )
}

export default WhyCanvendor