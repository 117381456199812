import React, { useRef } from 'react';

import NavComponent from '../components/NavComponent';

const EnquriyForm = () => {



  return (
    <>
       <NavComponent></NavComponent>
      <section id="enquriy" className="enquriy pb-5  pt-5 mt-5">
        <div className="container aos-init aos-animate" data-aos="fade-up">
        
                <div className="row ">
                  <div className="col-lg-5 d-flex align-items-stretch mt-5">
                    <div  data-aos='fade-left' >
                    <img className='img-fluid enimage' src={'http://www.canvendor.com/assets/images/Web_Banner_H14-20 (1).jpg'} style={{height:'500px' ,width:'600px'}}/>
                              </div>
                              </div>
                              <div className="col-lg-7  mt-lg-0 d-flex align-items-stretch" data-aos='fade-right'>
                             
                                <form  role="form" className="enform" >
                                  <div className="row">
                                  <h5 className='mx-4 px-4 mb-0' style={{fontFamily:'poppins',fontWeight:'bold',color:'rgb(31,33,72)'}}
                                  >Enquriy Form</h5>
                                  <p className='mx-4 px-4 mt-0' style={{fontFamily:'poppins',fontWeight:'bold',color:'rgb(31,33,72)'}}>Please fill all details</p>
                                    <div className="form-group col-md-6">
                                     
                                       <input type="text" name="from_name"  className="form-control" id="name" required="" placeholder='Name'/></div>
                                       <div className="form-group col-md-6">
                                    
                                       <input type="text" className="form-control" name="user_email" id="email" required="" placeholder='Designation'/>
                                       </div></div>
                                       <div className="row mt-3">
                                    <div className="form-group col-md-6 ">
                                       {/* <label for="name">Company Name</label>  */}
                                       <input type="text" name="from_name"  className="form-control" id="name" required="" placeholder='Company Name'/></div>
                                       <div className="form-group col-md-6">
                                         {/* <label for="name"></label>  */}
                                       <input type="text" className="form-control" name="user_email" id="email" required="" placeholder='Contact Number'/>
                                       </div></div>
                                       <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                       {/* <label for="name">Email </label>  */}
                                       <input type="email" name="from_name"  className="form-control" id="name" required="" placeholder='Email'/></div>
                                       <div className="form-group col-md-6"> 
                                       {/* <label for="name">Your Email</label>  */}
                                       <input type="text" className="form-control" name="user_email" id="email" required="" placeholder='Location'/>
                                       </div></div>
                                       <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                       {/* <label for="name">Location</label>  */}
                                       <input type="text" name="from_name"  className="form-control" id="name" required="" placeholder='Address'/></div>
                                       <div className="form-group col-md-6"> 
                                       {/* <label for="name">Your Email</label>  */}
                                       <input type="text" className="form-control" name="user_email" id="email" required="" placeholder='Your company website URL'/>
                                       </div></div>
                                       
                                       <div className="row mt-3">
                                       <div className="form-group col-md-12">
                                       
                                       <input type="text" name="from_name"  className="form-control" id="name" required="" placeholder='Canvendor Products / Services that you are interested in'/></div>
                                       </div>
                                
                                   <div className="row mt-3">
                                       <div className="form-group col-md-12">
                               
                                       <input type="email" className="form-control" name="user_email" id="email" required="" placeholder='Are you an Exhibitor in GITEX 2023'/>
                                       </div>
                                   </div>
                                    
                                       <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                       {/* <label for="name">Meeting time</label>  */}
                                       <input type="text" name="from_name"  className="form-control" id="name" required="" placeholder='Meeting time'/></div>
                                       <div className="form-group col-md-6">
                                         {/* <label for="name">Are you an Exhibitor in GITEX 2023</label>  */}
                                       <input type="text" className="form-control" name="user_email" id="email" required="" placeholder='Are you an Exhibitor in GITEX 2023'/>
                                       </div></div>
                                       <div className="row mt-3 mb-5">
                                    <div className="form-group col-md-6">
                                       {/* <label for="name">If so, please share Hall no. and Booth no.</label>  */}
                                       <input type="text" name="from_name"  className="form-control" id="name" required="" placeholder='If so, please share Hall no. and Booth no.'/></div>
                                       <div className="form-group col-md-6"> 
                                       {/* <label for="name">How did you hear about us</label>  */}
                                       <input type="text" className="form-control" name="user_email" id="email" required="" placeholder='How did you hear about us'/>
                                       </div></div>
                              <div className="text-center">
                                            <button type="submit">Send Message</button>
                                            </div>
                                            </form>
                                            </div>
                                            </div>
                                            </div>
                                            </section>
    </>
  )
}

export default EnquriyForm