import React from "react";
import NavComponent from "../../components/NavComponent";
import { Link } from "react-router-dom";
import { CgData, CgDatabase, CgStack } from "react-icons/cg";
import { DiAtlassian, DiCloud9 } from "react-icons/di";
import { GiArtificialIntelligence, GiHypodermicTest } from "react-icons/gi";
import { useEffect, useState, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Insurance = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <NavComponent></NavComponent>

      <div class="indus-page-title-container commu-indus-bg-img">
        <div class="container indus-title-content-container">
          <h2 className="indus-page-title" data-aos="flip-down">
            Insurance
          </h2>
          <p className="indus-page-tagline" data-aos="fade-up">
            {" "}
            Streamlining Insurance with Intelligent Solutions for Maximum
            Protection
          </p>
        </div>
      </div>

      <div class="container indus-content-section">
        <div class="row">
          <div class="col-lg-9">
            <span class="section-sub-title">Insurance</span>
            <h2 class="indus-content-title">
              Driving Insurance Transformation with State-of-the-Art Digital and
              Tech Solutions{" "}
            </h2>
            <p>
              At Canvendor, we deliver innovative digital and technological
              solutions tailored to the insurance sector.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <p>
              Our state-of-the-art services are designed to boost operational
              efficiency, lower costs, and improve the customer experience. By
              utilizing AI and advanced data processing technologies, we enable
              insurance companies to adeptly manage the complexities of today’s
              market with precision and confidence.
            </p>
          </div>
        </div>
      </div>

      <div className="container indus-our-services-section">
        <h1 className="indus-content-title">Our Offerings</h1>
        <p className="mb-5">
          Achieve operational excellence and outstanding customer service with
          our advanced insurance solutions designed for modern needs.
        </p>

        <div className="indus-service-grid">
          <div className="indus-service-item">
            <div className="tp-service__icon"></div>
            <h3 className="indus-service-item-title">
              Commercial & Specialty Insurance Solutions
            </h3>
            <p className="indus-service-item-para">
              Optimize your insurance operations with our advanced automation
              integration. Our solutions reduce costs, enhance efficiency, and
              improve outcomes by streamlining front and back-office processes.
              Deliver tailored experiences that meet your client's unique needs
              and boost overall satisfaction.
            </p>
          </div>
          <div className="indus-service-item">
            <div className="tp-service__icon"></div>
            <h3 className="indus-service-item-title">
              Personal Insurance Lines
            </h3>
            <p className="indus-service-item-para">
              Our solutions ensure a smooth omnichannel journey, from initial
              quotations to policy renewals. Digitize and optimize every step to
              provide your policyholders a seamless and efficient experience.
            </p>
          </div>
          <div className="indus-service-item">
            <div className="tp-service__icon"></div>
            <h3 className="indus-service-item-title">
              Life Insurance Technology Solutions
            </h3>
            <p className="indus-service-item-para">
              Upgrade your life insurance technology through strategic digital
              transformation. Our solutions enhance responsiveness, simplify
              complex processes, reduce operational risks, and drive long-term
              growth for your organization.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="tp-section-wrapper">
                <h2 className="tp-section-title tp-section-title-large">
                  <span style={{ color: "#1F2148" }}> AI-Powered Document</span>{" "}
                  Forgery Detection{" "}
                </h2>
                <p>
                  In the digital age, the threat of document forgery is
                  ever-present. Canvendor's AI-Powered Document Forgery
                  Detection service is your first line of defense against this
                  sophisticated fraud
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-md-6 tp">
                <div className="tp-service2  text-center white-bg  pb-45 pl-25 pr-25 mb-30">
                  <div className="tp-service__icon">
                    <CgDatabase
                      className="mb-2 iconser"
                      style={{
                        backgroundColor: "#1F2148",
                        width: "50px",
                        height: "30px",
                        color: "white",
                        padding: "5px",
                      }}
                    ></CgDatabase>
                  </div>
                  <h3 className="tp-service__title text-left pb-25">
                    Data Collection
                  </h3>
                  <p>
                    Amassing a vast dataset to ensure a robust foundation for
                    accurate detection.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 tp">
                <div className="tp-service2  text-center white-bg  pb-45 pl-25 pr-25 mb-30">
                  <div className="tp-service__icon">
                    <CgStack
                      className="mb-2 iconser"
                      style={{
                        backgroundColor: "#1F2148",
                        width: "50px",
                        height: "30px",
                        color: "white",
                        padding: "5px",
                      }}
                    ></CgStack>
                  </div>
                  <h3 className="tp-service__title text-left pb-25">
                    Analysis
                  </h3>
                  <p>
                    Employing advanced analytical techniques to identify
                    patterns and indicators of forgery.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 tp">
                <div className="tp-service2  text-center white-bg  pb-45 pl-25 pr-25 mb-30">
                  <div className="tp-service__icon">
                    <DiAtlassian
                      className="mb-2 iconser"
                      style={{
                        backgroundColor: "#1F2148",
                        width: "50px",
                        height: "30px",
                        color: "white",
                        padding: "5px",
                      }}
                    ></DiAtlassian>
                  </div>
                  <h3 className="tp-service__title text-left pb-25">
                    Training Algorithm
                  </h3>
                  <p>
                    Crafting a bespoke AI algorithm that learns from the data to
                    spot forgeries with unparalleled accuracy.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 tp">
                <div className="tp-service2  text-center white-bg  pb-45 pl-25 pr-25 mb-30">
                  <div className="tp-service__icon">
                    <GiArtificialIntelligence
                      className="mb-2 iconser"
                      style={{
                        backgroundColor: "#1F2148",
                        width: "50px",
                        height: "30px",
                        color: "white",
                        padding: "5px",
                      }}
                    ></GiArtificialIntelligence>
                  </div>
                  <h3 className="tp-service__title text-left pb-25">
                    Generating AI Model
                  </h3>
                  <p>
                    Developing a state-of-the-art AI model that integrates
                    seamlessly with your existing systems.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 tp">
                <div className="tp-service2  text-center white-bg  pb-45 pl-25 pr-25 mb-30">
                  <div className="tp-service__icon">
                    <GiHypodermicTest
                      className="mb-2 iconser"
                      style={{
                        backgroundColor: "#1F2148",
                        width: "50px",
                        height: "30px",
                        color: "white",
                        padding: "5px",
                      }}
                    ></GiHypodermicTest>
                  </div>
                  <h3 className="tp-service__title text-left pb-25">Testing</h3>
                  <p>
                    Rigorously testing the model to ensure it meets the highest
                    standards of reliability and efficiency.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 tp">
                <div className="tp-service2  text-center white-bg  pb-45 pl-25 pr-25 mb-30">
                  <div className="tp-service__icon">
                    <DiCloud9
                      className="mb-2 iconser"
                      style={{
                        backgroundColor: "#1F2148",
                        width: "50px",
                        height: "30px",
                        color: "white",
                        padding: "5px",
                      }}
                    ></DiCloud9>
                  </div>
                  <h3 className="tp-service__title text-left pb-25">
                    Deploying
                  </h3>
                  <p>
                    Seamlessly integrating the AI model into your operations,
                    transforming your document verification processes
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* 
                <div className='container'>
               <div className='row'>
               <div className='col-lg-9'>
    <div className='tp-section-wrapper'>
        <span className='tp-section-subtitle tp-subtitle-before mb-3'>Consulting</span>
        <h2 className='tp-section-title tp-section-title-large'> 

        AI/ ML SOLUTIONS  </h2>
<p> At Canvendor, IT consulting is our forte. Our team is empowered to solve an array of business snags at any stage of digital transformation. The team is adept at eliminating gaps and building smooth-sailing roadmaps that give our customers a competitive advantage over their competition. </p>
    </div>
</div>
<div className='col-lg-3 d-flex justify-content-lg-end'>
    <img src='../robotic-automation.png' width={250}></img>
</div>
               </div>
                </div> */}
    </>
  );
};

export default Insurance;
