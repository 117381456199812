import React from "react";
import "./indus-css/style.css";
import NavComponent from "../../components/NavComponent";
import { Link } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const Manufacturing = () => {
  useEffect(() => {   
    window.scrollTo(0, 0); 
   }, []);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <NavComponent></NavComponent>
      <div class="indus-page-title-container manu-indus-bg-img">
        <div class="container indus-title-content-container">
          <h2 className="indus-page-title" data-aos="flip-down">
            Manufacturing
          </h2>
          <p className="indus-page-tagline" data-aos="fade-up">
            {" "}
            Driving Excellence in Manufacturing Through Innovation and
            Technological Advancements.
          </p>
        </div>
      </div>

      <div class="container indus-content-section">
        <div class="row">
          <div class="col-lg-9">
            <span class="section-sub-title">Manufacturing</span>
            <h2 class="indus-content-title">
              Transforming Manufacturing with Smart Factories
            </h2>
            <p>
              {" "}
              At Canvendor, we are revolutionizing the manufacturing sector with
              cutting-edge technologies like the Internet of Things (IoT), cloud
              computing, AI, and machine learning. Our industrial consulting
              services are not just enhancing how companies manufacture,
              improve, and distribute products but also inspiring a new era of
              innovation and efficiency.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <p>
              Digital technologies drive increased automation, predictive
              maintenance, self-optimization, and unparalleled visibility from
              previously siloed information. We enable smart factories to thrive
              in a customer-obsessed market, producing customized goods
              cost-effectively.
            </p>
            <p class="text-dark">
              Manufacturing drives innovation, employment, and economic growth
              by transforming raw materials into finished products. Advanced
              techniques like automation and 3D printing revolutionize
              production, increasing efficiency and reducing costs. Quality
              control ensures products meet industry standards, fostering
              consumer trust.
            </p>
          </div>
        </div>
      </div>

      <div class="container indus-our-services-section">
        <h1 class="indus-content-title ">Our Offerings</h1>
        <p className="mb-5">
          Driving excellence in manufacturing with advanced technologies and
          sustainable practices across multiple sectors.
        </p>

        <div class="indus-service-grid">
          <div class="indus-service-item">
            <div class="tp-service__icon"></div>
            <h3 class="indus-service-item-title">
              Diverse Manufacturing Sectors
            </h3>
            <p class="indus-service-item-para">
              Manufacturing spans various sectors such as automotive,
              electronics, aerospace, and textiles, each with unique products
              and processes. From vehicles and consumer electronics to clothing
              and machinery, the diversity in manufacturing meets society's
              complex needs and demands.
            </p>
          </div>
          <div class="indus-service-item">
            <div class="tp-service__icon"></div>
            <h3 class="indus-service-item-title">
              Advanced Manufacturing Technologies
            </h3>
            <p class="indus-service-item-para">
              Our advanced manufacturing technologies, including automation,
              robotics, 3D printing, and artificial intelligence, are not only
              enhancing productivity but also driving efficiency and reducing
              costs. These cutting-edge techniques are not just about innovation
              but also about practical benefits and improved product quality.
            </p>
          </div>
          <div class="indus-service-item">
            <div class="tp-service__icon"></div>
            <h3 class="indus-service-item-title">
              Sustainable Manufacturing Practices
            </h3>
            <p class="indus-service-item-para">
              Our sustainable manufacturing practices are about minimizing
              environmental impact and taking responsibility for our business
              practices. We ensure efficient resource use, lower emissions, and
              eco-conscious product design by using eco-friendly materials,
              reducing waste, conserving energy, and implementing recycling
              initiatives.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Manufacturing;
