import React from "react";
import NavComponent from "../../components/NavComponent";
import "../services/service-css/style.css";
import { Link } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const WebandMobileService = () => {
  useEffect(() => {   
    window.scrollTo(0, 0); 
   }, []);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <NavComponent></NavComponent>
      <section className="app-services-page-head-bg overflow-hidden">
        <div className="container service-bg-container ">
          <div>
            <h1 className="service-inner-page-title" data-aos="flip-down">
              Application Engineering
            </h1>
            <div className="page-direction" data-aos="fade-up">
              <span className="ps-0">
                <Link to="/">Home</Link>
              </span>
              <span>
                <Link to="/services">capabilities</Link>
              </span>
              <p>Application Engineering</p>
            </div>
          </div>
          <img
            className="app-img"
            src="/app-services-page-img.png"
            data-aos="fade-left"
            data-aos-duration="3000"
          />
        </div>
      </section>

      <div className="container service-content-section">
        <div className="row">
          <div className="col-lg-10">
            <div className="">
              <span className="section-sub-title">
                Our misson is grow your Business
              </span>
              <h2 className="service-page-content-title">
                Premium App Development Services{" "}
              </h2>
              <p>
                {" "}
                At Canvendor, we deliver comprehensive app development services,
                covering every stage from design and integration to
                implementation and management. Our expertise spans a wide range
                of mobile applications, including eCommerce apps,
                consumer-focused solutions, and transformative enterprise
                applications. Trust Canvendor for end-to-end app development
                tailored to meet your specific needs.{" "}
              </p>

              {/* <p>Canvendor simplifies and modernizes the existing landscape and helps our customers stay ahead in the digital economy.The enterprise application of any organization is at the core of its IT structure. Canvendor helps digitally transform multiple processes in an organization including CX, CRM, HCM, SCMM, and ERP </p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid app-our-offerings">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h1 className="service-page-offering-title">Our Offerings</h1>
              <p className="service-page-offering-para">
                See how our tailored solutions and services can help you achieve
                your goals with efficiency and precision.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container service-card-margin">
        <div className="service-offering-section">
          <div className="service-offering-card">
            <div className="">
              <div className="service-offering-card-icon">
                <img src="/Native Mobile App Development.png" />
              </div>
              <h3 className="service-offering-card-title">
                Native Mobile App Development
              </h3>
              <p className="service-offering-card-content">
                At Canvendor, our expert developers create high-performance
                native apps for both Android and iOS platforms. Tailored to your
                business needs, our native app solutions offer superior
                functionality and user experience.
              </p>
            </div>
          </div>
          <div className="service-offering-card">
            <div className="">
              <div className="service-offering-card-icon">
                <img src="/Hybrid Mobile App Development.png" />
              </div>
              <h3 className="service-offering-card-title">
                Hybrid Mobile App Development
              </h3>
              <p className="service-offering-card-content">
                Our Hybrid Mobile App Development services build versatile
                cross-platform applications using advanced technologies. These
                apps deliver a seamless experience across various devices and
                operating systems, combining the best features of both native
                and web applications.
              </p>
            </div>
          </div>
          <div className="service-offering-card">
            <div className="">
              <div className="service-offering-card-icon">
              <img src="/Progressive Web App Development.png" />
              </div>
              <h3 className="service-offering-card-title">
                Progressive Web App Development
              </h3>
              <p className="service-offering-card-content">
                Canvendor specializes in Progressive Web App (PWA) Development,
                creating apps with native-like capabilities that are easy to
                install and accessible on any device. Our PWAs provide a unified
                user experience with a single codebase, enhancing performance
                and engagement.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container service-content-section">
        <div className="row">
          <div className="col-lg-10">
            <div className="">
              <span className="section-sub-title">Consulting </span>
              <h2 className="service-page-content-title">
                Why Choose Us for Application Development Services{" "}
              </h2>
              <p>
                {" "}
                At Canvendor, we deliver cutting-edge application development
                services tailored to your unique needs. Our expert team
                leverages the latest technologies and best practices to create
                scalable, high-performance applications that drive business
                success. Choose us for innovative solutions, reliable delivery,
                and unmatched support, ensuring your applications excel in
                functionality and user experience.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebandMobileService;
