import React, { forwardRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './Applyingnew.css'
import Experience from './Experience'
import FormHeader from './FormHeader'
import Personalinfo from './Personalinfo'
const CareerForm = forwardRef((props, ref) => {
  const {initialData} = props

  return (
    <>
<Personalinfo    initialData={initialData}></Personalinfo>
    </>
    
  )

})
CareerForm.defaultProps = {
    initialData: {
        id: '',
        firstName:'',
        middleName:'',
        lastName:'',
        gender:'',
        email:'',
        altemail:'',
        resumeDetails:'',
        countryId:'',
        countryName:'',
        address:'',
        phone:'',
        altphone:'',
        jobTitle:'',
        applicantStatus:'',
        rate:'',
        rateType:'',
        source:'',
        stateId:null,
        city:'',
        workAuthorization:'',
        experienceMonths:0,
        experienceYears:0,
        skills:[],
        skillsArray:[],
        skillIndustry:null,
        skillDegree:null,
        additionalSkills:null,
        selectedStates:null,
        zipcode:null,
        educationList:[],
        experienceList:[],
        documentList:[]
        
    },
}
export default CareerForm