import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import NavComponent from '../../components/NavComponent'
import Applyingnew from './Applyingnew'
import FormHeader from './FormHeader'
import Experience from './Experience'
const Personalinfo = (props) => {
  const { initialData } = props
  const [candidateInfo, setCandidateInfo] = useState(initialData);
    const addFormExperience = () => {
     
        let prevExperiDetails = Object.entries(candidateInfo.experienceList);//candidateInfo.educationList;
        prevExperiDetails.push({ jobTitle:"",comName:"",location:"",strDate:"",endDate:"",description:""});
        setCandidateInfo({ ...candidateInfo, "experienceList": prevExperiDetails });
    }
  return (
    <>
    <FormHeader/>
    <div className='container mt-5 pt-5'>
    <div className='personalDetail form-section'>
<h4 className='mb-5'>Personal Information</h4>
<form class="row g-3">
<div class="col-md-6">
    <label for="inputEmail4" class="form-label">First name</label>
    <input type="text" class="form-control" id="inputEmail4"/>
  </div>
  <div class="col-md-6">
    <label for="inputPassword4" class="form-label">Last name</label>
    <input type="text" class="form-control" id="inputPassword4"/>
  </div>
  <div class="col-md-6">
    <label for="inputEmail4" class="form-label">Email</label>
    <input type="email" class="form-control" id="inputEmail4"/>
  </div>
  <div class="col-md-6">
  <label for="inputEmail4" class="form-label">Confirm your email</label>
    <input type="email" class="form-control" id="inputEmail4"/>
  </div>
  <div class="col-md-6">
    <label for="inputEmail4" class="form-label">Place of residence</label>
    <input type="text" class="form-control" id="inputEmail4"/>
  </div>
  <div class="col-md-6">
  <label for="inputEmail4" class="form-label">Phone number</label>
    <input type="tel" class="form-control" id="inputEmail4"/>
  </div>


  
</form>

    </div>
 
    <div className='personalDetail form-section'>
        <div className='row'>
        <div class="col-md-6">
        <h4 class="form-section-header form-section-header--required"> Experience </h4>
        </div>
        <div class="col-md-6">
    <button data-test="add-experience" class="button button--secondary button--rounded" aria-expanded="false" aria-label="Add experience entry" fdprocessedid="y0y9nc" onClick={() =>addFormExperience()}><i class="icon icon--oc-plus"  ></i><span class="truncate" >Add</span></button>
    </div>
        </div>
        
        {candidateInfo.experienceList.map((det, index) => {
                            return <Experience initialData={det} indexKey={index}
                                 />
                        })}
    </div>
    </div>
    
    </>
    
  )
}

export default Personalinfo