import React from "react";
import "./indus-css/style.css";
import NavComponent from "../../components/NavComponent";
import { useEffect, useState, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const Automotive = () => {
  useEffect(() => {   
    window.scrollTo(0, 0); 
   }, []);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <NavComponent></NavComponent>

      <div class="indus-page-title-container auto-indus-bg-img">
        <div class="container indus-title-content-container">
          <h2 className="indus-page-title" data-aos="flip-down">
            Automotive
          </h2>
          <p className="indus-page-tagline" data-aos="fade-up">
            {" "}
            Engineered for Success with Pioneering Solutions in the Automotive
            Industry
          </p>
        </div>
      </div>

      <div class="container indus-content-section">
        <div class="row">
          <div class="col-lg-9">
            <span class="section-sub-title">Automotive</span>
            <h2 class="indus-content-title">
              Pioneering Intelligent Solutions in the Automotive World
            </h2>
            <p>
              {" "}
              Experience a new era of automotive excellence with smart,
              high-performance, eco-friendly vehicles that offer unparalleled
              audio-visual experiences, advanced telematics, enhanced mobility,
              and powerful analytics, all within a cost-effective package.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <p>
              The automotive industry is at the cutting edge of technological
              progress, transforming travel and redefining transportation. This
              dynamic sector includes the design, development, manufacturing,
              and sales of various vehicles, from cars and trucks to motorcycles
              and electric vehicles. The industry is growing daily, driven by
              continuous advancements such as autonomous driving, electric
              propulsion, and smart connectivity.{" "}
            </p>
            <p class="text-dark">
              {" "}
              Global automotive companies invest significantly in research and
              development to produce fuel-efficient and environmentally friendly
              vehicles. These innovations aim to reduce emissions, promote
              sustainability, and advance green technologies.
            </p>
          </div>
        </div>
      </div>

      <div class="container indus-our-services-section">
        <h1 class="indus-content-title ">Our Offerings</h1>
        <p className="mb-5">
          Our services offer industry-leading solutions that cover technology
          advancements and sustainable practices in automotive manufacturing.
        </p>

        <div class="indus-service-grid">
          <div class="indus-service-item">
            <div class="tp-service__icon"></div>
            <h3 class="indus-service-item-title">
              Overview of the Automotive Sector
            </h3>
            <p className="indus-service-item-para">
              The automotive industry encompasses the entire lifecycle of motor
              vehicles, including their design, development, manufacturing,
              marketing, and sales. This sector covers vehicles such as cars,
              trucks, buses, and motorcycles. As a crucial component of the
              global economy, the automotive industry generates employment,
              stimulates technological innovation, and drives progress across
              multiple sectors.
            </p>
          </div>

          <div class="indus-service-item">
            <div class="tp-service__icon"></div>
            <h3 class="indus-service-item-title">
              Cutting-edge trends in Automotive Technology
            </h3>
            <p className="indus-service-item-para">
              Stay updated with the latest advancements in automotive
              technology. Key trends include the rise of electric vehicles
              (EVs), the evolution of autonomous driving systems, the
              integration of connected vehicle technologies, and the use of
              advanced materials in vehicle production. Explore how hybrid
              propulsion systems are transforming the industry and shaping the
              future of transportation.
            </p>
          </div>

          <div class="indus-service-item">
            <div class="tp-service__icon"></div>
            <h3 class="indus-service-item-title">
              Promoting Environmental Sustainability in Automotive Manufacturing
            </h3>
            <p className="indus-service-item-para">
              Adopting sustainable manufacturing practices is essential for
              minimizing the environmental footprint of vehicle production. This
              includes using eco-friendly materials, waste reduction strategies,
              energy conservation, and recycling initiatives. Emphasizing
              sustainability ensures efficient resource use and reduced
              emissions throughout the manufacturing process.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Automotive;
