import React from "react";
import NavComponent from "../../components/NavComponent";
import { useEffect, useState, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const Healthcare = () => {
  useEffect(() => {   
    window.scrollTo(0, 0); 
   }, []);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <NavComponent></NavComponent>

      <div class="indus-page-title-container health-indus-bg-img">
        <div class="container indus-title-content-container">
          <h2 className="indus-page-title" data-aos="flip-down">
            Healthcare
          </h2>
          <p className="indus-page-tagline" data-aos="fade-up">
            {" "}
            Leading the Future of Healthcare Through Innovative Technological
            Solutions
          </p>
        </div>
      </div>

      <div class="container indus-content-section">
        <div class="row">
          <div class="col-lg-9">
            <span class="section-sub-title">Healthcare</span>
            <h2 class="indus-content-title">
              Enhancing Personalized Healthcare Experiences
            </h2>
            <p>
              {" "}
              At Canvendor, we emphasize personalizing healthcare experiences,
              prioritizing them strategically. Our approach centers on using
              advanced technology to improve patient care. By analyzing
              healthcare data, we generate AI-driven insights that guide
              patients in choosing the right healthcare providers, taking
              preventive measures, and consolidating their medical records in
              one accessible platform.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <p>
              Our connected care technology is designed to streamline the
              healthcare journey for both providers and patients. We create
              intuitive platforms that facilitate seamless navigation through
              the health ecosystem, enhancing the overall experience.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="tab mt-5">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <p>
                At Canvendor, we put humans at the centre of care and leverage
                technology to improve healthcare experiences for all. Delivering
                highly personalised experience to end customers is a top
                strategic priority in the healthcare domain. We achieve this
                with patients' insights uncovered from the healthcare data
                points. These suggest AI-generated solutions on where to seek
                healthcare from, preventative actions they must undertake, and
                have their entire healthcare record consolidated in one place.{" "}
              </p>
            </div>
            <div className="col-6">
              <p className="text-dark">
                {" "}
                Our connected care technology solutions aim at designing
                platforms for providers and patients to easily navigate through
                the health ecosystem and know what their healthcare journey will
                look like. Canvendor’s business process solutions are set up for
                healthcare providers to improve real-time customer
                responsiveness, help meet their regulatory compliance, and
                provide cost competitiveness.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div class="container indus-our-services-section">
        <h1 class="indus-content-title">Our Offerings</h1>
        <p className="mb-5">
          Empowering healthcare providers with innovative IT solutions designed
          to enhance patient care, streamline operations, and ensure data
          integrity.
        </p>

        <div class="indus-service-grid">
          <div class="indus-service-item">
            <div class="tp-service__icon"></div>
            <h3 class="indus-service-item-title">
              Holistic Wellness and Healing
            </h3>
            <p className="indus-service-item-para">
              Healthcare involves systematically delivering medical services,
              including diagnosis, treatment, and illness prevention. It is
              crucial for safeguarding and enhancing physical, mental, and
              social well-being, and a diverse range of professionals,
              technologies, and institutions supports it.
            </p>
          </div>
          <div class="indus-service-item">
            <div class="tp-service__icon"></div>
            <h3 class="indus-service-item-title">
              Broad Range of Healthcare Services
            </h3>
            <p className="indus-service-item-para">
              Our healthcare services include primary care, specialized nursing,
              pharmacy, and therapy treatments. We also utilize advanced
              technologies like telemedicine and medical robotics to improve
              patient care, disease prevention, and overall health management.
            </p>
          </div>
          <div class="indus-service-item">
            <div class="tp-service__icon"></div>
            <h3 class="indus-service-item-title">
              Impact and Challenges of Healthcare Systems
            </h3>
            <p className="indus-service-item-para">
              Healthcare systems play a critical role in shaping societal
              outcomes, such as life expectancy and quality of life. They face
              ongoing challenges, including disparities in access, increasing
              costs, and emerging health threats. Continuous research, public
              health initiatives, and medical innovations are essential to
              overcoming these challenges and improving healthcare delivery.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Healthcare;
