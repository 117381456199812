import React from "react";
import { AiOutlineCloudServer } from "react-icons/ai";
import { DiAtlassian, DiDigitalOcean } from "react-icons/di";
import { BsBuilding } from "react-icons/bs";
import { GiArtificialIntelligence, GiDiscussion } from "react-icons/gi";
import { CgDatabase, CgWebsite } from "react-icons/cg";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Button } from "bootstrap";
import { Link } from "react-router-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useEffect, useState, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const HomeServices = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <div className="our-company ">
        <div className="container home-service-swiper-container">
          <div className="home-service-title-container">
            <div>
              <h5 className="section-sub-title" data-aos="fade-up">Services</h5>
              <h2 className="service-section-title" data-aos="fade-up">Explore Our Services</h2>
            </div>
            <div className="">
              <Link to="/services" >
                {" "}
                <a className="home-services-btn" href="/services" data-aos="fade-up">
                  View All
                </a>
              </Link>
            </div>
          </div>

          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={20}
            navigation
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
              1: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              992: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1400: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide>
              <div className="service-card" data-aos="fade-up">
                <Link to="/services/aiml">
                  {" "}
                  <a className="service-card-content">
                    <img src="artificial.jpg"></img>
                    <div className="home-service-card-text">
                      <h3 className="service-card-title ">AI/ML Solutions</h3>
                      <p>
                        Drive business growth with AI/ML innovations, optimizing
                        processes and delivering actionable insights for
                        strategic advantages.
                      </p>
                    </div>
                  </a>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="service-card" data-aos="fade-up">
                <Link to="/services/cloudSolutions">
                  <a className="service-card-content">
                    <img src="Cloud Solutions.jpg"></img>
                    <div className="home-service-card-text">
                      <h3 className="service-card-title ">Cloud Solutions</h3>
                      <p>
                        Empower your business with agile Cloud Solutions,
                        enabling rapid deployment and seamless integration for
                        enhanced productivity and growth.
                      </p>
                    </div>
                  </a>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="service-card" data-aos="fade-up">
                <Link to="/services/itConsultingService">
                  {" "}
                  <a className="service-card-content">
                    <img src="IT Consulting.jpg"></img>
                    <div className="home-service-card-text">
                      <h3 className="service-card-title ">IT Consulting</h3>
                      <p>
                        Optimize performance and accelerate growth with our
                        proactive IT consulting, providing strategic guidance
                        and implementation support.
                      </p>
                    </div>
                  </a>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="service-card" data-aos="fade-up">
                <Link to="/services/webAppService">
                  {" "}
                  <a className="service-card-content">
                    <img src="Applications Solutions.jpg"></img>
                    <div className="home-service-card-text">
                      <h3 className="service-card-title ">
                        Application Engineering
                      </h3>
                      <p>
                        Transform your business processes with our innovative
                        application solutions, tailored to deliver seamless
                        integration and enhanced performance.
                      </p>
                    </div>
                  </a>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="service-card" data-aos="fade-up">
                <Link to="/services/digitalTransformation">
                  <a className="service-card-content">
                    <img src="Digital Transformation.jpg"></img>
                    <div className="home-service-card-text">
                      <h3 className="service-card-title ">
                        Digital Transformation
                      </h3>
                      <p>
                        Accelerate your digital journey with our strategic
                        transformation services. We leverage advanced
                        technologies to reshape processes, enhance efficiency,
                        and position your business for sustained success.
                      </p>
                    </div>
                  </a>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="service-card" data-aos="fade-up">
                <Link to="/services/enterpriseService">
                  {" "}
                  <a className="service-card-content">
                    <img src="Enterprise Solutions.jpg"></img>
                    <div className="home-service-card-text">
                      <h3 className="service-card-title ">
                        Enterprise Solutions
                      </h3>
                      <p>
                        Strategic enterprise solutions harnessing AI and cloud
                        technologies to deliver efficient, scalable business
                        solutions for modern challenges.
                      </p>
                    </div>
                  </a>
                </Link>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* <div className='row'>
	<div className='col-xl-3 col-md-6 tp'>
		<div className='tp-service text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<CgWebsite  className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>It Consulting</a>
			</h3>
			<p>
			Insurance ERP is a boon to organizations which help in integrating all company data from client  invoicing.	
			</p>
			<a className='tp-btn-sm-black '><span>View MOre</span></a>
		</div>
	</div>
		<div className='col-xl-3 col-md-6 tp'>
		<div className='tp-service text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<BsBuilding  className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>Enterprises Solutions</a>
			</h3>
			<p>
			The perfect solutions to manage your sales process and optimize pipeline generation.	
			</p>
			<a className='tp-btn-sm-black '><span>View MOre</span></a>
		</div>
	</div>
	<div className='col-xl-3 col-md-6 tp'>
		<div className='tp-service text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<GiArtificialIntelligence  className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>AI/ML SOLUTIONS</a>
			</h3>
			<p>
			Discover your applications with easily map comprehensive insight into your complex IT.	
			</p>
			<a className='tp-btn-sm-black'><span>View MOre</span></a>
		</div>
	</div>
		<div className='col-xl-3 col-md-6 tp'>
		<div className='tp-service text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30'>
			<div className='tp-service__icon'>
			<AiOutlineCloudServer className='mb-2 iconser' style={{color: "#171717"}}/>
			</div>
			<h3 className='tp-service__title pt-40 pb-25'>
				<a>CLOUD SOLUTIONS</a>
			</h3>
			<p>
			A web application is a software application that the user interacts with via a browser.
			</p>
			<a className='tp-btn-sm-black'><span>View MOre</span></a>
		</div>
	</div>
	<div className='text-center mt-60 allser'>
		<a className='tp-btn-border' href='/services'>All Services</a>
		
	</div>
</div>

</div> */}
      </div>

      {/* <section id="services" class="common-section grey-section">
        <article class="container-fluid mt-5 mb-5">
            <div class="row ">

                <div class="col-md-4">
                    <h1 class="main-head  wow fadeInLeft animated pt-5" data-wow-delay=".5s" >OUR SERVICES</h1>
                
                    <div><span className="head-cube fl-left wow fadeInDown animated pt-5" data-wow-delay=".5s" ></span></div>
                    <p  style={{paddingTop:'50px' ,paddingLeft:'30px',lineHeight:'30px'}}>Web applications are growing in popularity because they can be updated  and maintained without having to distribute or install software on computers and because of their cross-platform compatibility.</p>
                    <p style={{paddingTop:'50px' ,paddingLeft:'30px',lineHeight:'30px'}}>
                        We can also develop from the simplest website to the most complex web-based applications and recommend the configuration that best meets your objectives. 
                    </p>
                </div>
                <div class="col-md-8 ">



                    <div class="serv_item  wow fadeInLeft animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
						<CgWebsite size={60} className='mb-2 iconser' style={{color: "#001a57"}}/>
                            <h3>IT CONSULTING</h3>
                            <p class="serv_cont_desc">
                                Insurance ERP is a boon to organizations which help in integrating all company data from client enquiry to invoicing.
                            </p>
                            <a href="service.html" class="animsition-link">MORE</a>
                        </article>
                    </div>
                    <div class="serv_item  wow fadeInLeft animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
						<BsBuilding size={60} className='mb-2 iconser' style={{color: "#001a57"}} />
                            <h3>Enterprises Solutions</h3>
                            <p class="serv_cont_desc">The perfect solutions to manage your sales process and optimize pipeline generation.</p>
                            <a href="service.html" class="animsition-link">MORE</a>
                        </article>
                    </div>
                    <div class="serv_item  wow fadeInLeft animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
							<GiArtificialIntelligence className='mb-2 iconser' size={60} style={{color:'#001a57'}}/>
                            <h3>Web/ Mobile App Development Solutions</h3>
                            <p class="serv_cont_desc">Discover your applications with easily map their relationships to gain a comprehensive insight into your complex IT infrastructure.</p>
                            <a href="service.html" class="animsition-link">MORE</a>
                        </article>
                    </div>
                    <div class="serv_item wow fadeInRight animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
							<AiOutlineCloudServer size={60} className='mb-2 iconser'  style={{ color: "#001a57" }}/>

                            <h3>AI/ML SOLUTIONS</h3>
                            <p class="serv_cont_desc">earning professionals must demonstrate strong consulting skills to successfully guide their organizations toward optimal performance.</p>
                            <a href="#">MORE</a>
                        </article>
                    </div>
                    <div class="serv_item wow fadeInRight animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
						<GiDiscussion size={60} className='mb-2 iconser'  style={{ color: "#001a57" }}/>
                            <h3>CLOUD SOLUTIONS</h3>
                            <p class="serv_cont_desc">
                                A web application is a software application that the user interacts with via a browser.We specialise in taking and making your business processes online. 
                            </p>
                            <a href="#">MORE</a>
                        </article>
                    </div>
                    <div class="serv_item wow fadeInRight animated" data-wow-delay=".5s" >
                        <article class="serv_cont">
						<DiDigitalOcean size={60} className='mb-2 iconser'  style={{color:'#001a57'}}/>
                            <h3>Help &amp; Support</h3>
                            <p class="serv_cont_desc">IvoxPark’s claims management software helps guide adjusters through each claim to expedite.</p>
                            <a href="#">MORE</a>
                        </article>
                    </div>

                </div>

            </div>
        </article>
    </section> */}
    </>
  );
};

export default HomeServices;
