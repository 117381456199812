import React, { useEffect, useState } from "react";

import { BsArrowRight } from "react-icons/bs";
import { GiArrowed } from "react-icons/gi";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Fade } from "react-awesome-reveal";

const HomeAbout = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  const [buttonStyle, setButtonStyle] = useState({
    float: "right",
    maxWidth: "200px",
    backgroundColor: "#0E0E47",
    color: "#fff",
    marginBottom: "25px",
  });

  const handleHover = () => {
    setButtonStyle({
      float: "right",
      maxWidth: "200px",
      border: "1px solid #47b2e4",
      color: "#47b2e4",
      marginBottom: "25px",
    });
  };
  const handleMoverLeave = () => {
    setButtonStyle({
      float: "right",
      maxWidth: "200px",
      backgroundColor: "#47b2e4",
      color: "#fff",
      marginBottom: "25px !important",
    });
  };

  return (
    <>
      <div className="about-section-container container-fluid">
        <div className="container">
          <div className="row about-content-container">
            <div className=" col-lg-7 order-lg-2">
              <div className="mb-4">
                <h5 className="section-sub-title" data-aos="fade-up">
                  About Us
                </h5>
                <h1 className="about-section-title" data-aos="fade-up">
                  We harness the immense capabilities of the digital world
                </h1>

                <p className="p-content" data-aos="fade-up">
                  At Canvendor, we empower businesses with digital solutions. As
                  pioneers in digital transformation, we specialize in
                  revitalizing technology landscapes and reimagining processes
                  to drive unparalleled growth for our global clientele.
                </p>
                <Link to="/about">
                  <button className="about-rm-btn" data-aos="fade-up">
                    Read More
                  </button>
                </Link>
              </div>
              <div className="about-features" data-aos="fade-up">
                <div className="about-feature-item ">
                  <img src="Innovative Solutions.png" />
                  <h3>Innovative Solutions</h3>
                </div>

                <div className="about-feature-item ">
                  <img src="Global Expertise.png" />
                  <h3>Global Expertise</h3>
                </div>

                <div className="about-feature-item ">
                  <img src="Customer-Centric Approach.png" />
                  <h3>Customer-Centric Approach</h3>
                </div>

                <div className="about-feature-item ">
                  <img src="Continuous Improvement.png" />
                  <h3>Continuous Improvement</h3>
                </div>
              </div>
            </div>

            <div className="about-content col-lg-5 " data-aos="fade-up">
              <div className="about-img-over">
                <img src="/about-content-img.png" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section
        class="our-mission-vision-section"
        style={{ position: "relative" }}
      >
        <div className="container our-mission-vision-slogan">
          <div className="row">
            <div className="col-lg-7 col-xl-8 our-mission-vision">
              <h5 className="section-sub-title" data-aos="fade-up">
                Our Mission and Vision
              </h5>
              {/* <h3 className="our-mission-vision-title">Our Mission and Vision</h3> */}
              <p className="our-mission-vision-para" data-aos="fade-up">
                Canvendor co-builds a shared vision with our customers and
                focuses on accomplishing their business end goal.
              </p>
            </div>
            <div
              className="col-lg-5 col-xl-4  our-mission-vision-brand"
              data-aos="fade-up"
            >
              <img src="/mission-vision-img.png" />
            </div>
          </div>
        </div>
        {/* <div className="container-fluid ">
          <div className="container our-mission-vision-content">
            <div className="row">
              <div className="col-12 ">
                <div className="container our-mission-vision-container">
                  <div className="row">
                    <div className="col-lg-8 ">
                      <div className="mission-vision-item-bg">
                        <div className=" mission-vision-item mission-item">
                          
                          <div className="mission-vision">
                            <h5>Mission</h5>
                            <p>
                              Empower businesses with innovative digital
                              solutions that drive transformation, efficiency,
                              and growth by developing pioneering technologies
                              tailored to unique business needs, partnering with
                              clients to achieve measurable results and
                              sustainable development, and streamlining
                              processes to enhance operational performance.
                            </p>
                          </div>
                        </div>

                        <div className=" mission-vision-item">
                          
                          <div className="mission-vision">
                            <h5>Vision</h5>
                            <p>
                              To be the leading force in digital transformation,
                              enabling global clients to thrive in the
                              technological orbit by setting benchmarks in
                              digital transformation and innovation, expanding
                              our impact to help businesses worldwide adapt and
                              excel, and fostering long-term success through
                              continuous improvement and adaptability.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mission-vision-img">
                      <div className="mission-vision-img-container">
                        <div className="mission-vision-img-content ">
                          <img src="/mission-vision.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default HomeAbout;
