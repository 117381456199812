import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useLocation } from "react-router-dom";
import "./css/banner.css";
import AOS from "aos";
import "aos/dist/aos.css";
const NavComponentHome = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: false,
    });
  }, []);

  // scroll to change logo
  const [imageSrc, setImageSrc] = useState(
    "../assets/images/canvendor_white_transparent_logo.png"
  ); // Initial image source

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Example logic to change the image source based on scroll position
      if (scrollPosition < 1) {
        setImageSrc("../assets/images/canvendor_white_transparent_logo.png");
      } else if (scrollPosition >= 50 && scrollPosition < 600) {
        setImageSrc("../assets/images/canvendor_blue_transparent_logo.png");
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures this effect runs once on mount

  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef();
  navRef.current = navBackground;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [pathname, setPathname] = useState("/");
  const location = useLocation();
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      setScrollPosition(currentScrollPosition);
      setPathname(location.pathname);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  let navStyle, linkStyle;
  if (pathname != "/") {
    navStyle = {
      // backgroundColor: '#fff',
      //  height:'20px',
      transition: "background-color 0.5s ease-in-out",
    };

    linkStyle = {
      color: "#0E0E47",

      transition: "color 0.5s ease-in-out",
    };
  } else {
    navStyle = {
      backgroundColor: scrollPosition > 75 ? "#fff" : "transparent",
      transition: "background-color 0.5s ease-in-out",
    };

    linkStyle = {
      color: scrollPosition > 75 ? "#0E0E47" : "#fff",
      transition: "color 0.5s ease-in-out",
    };
  }

  // return (
  //   <>
  //     <header id="header" className="fixed-top header-inner-pages header-scrolled header-test" style={navStyle}>
  //       <div className="container d-flex align-items-center">
  //         <h1 className="logo me-auto " style={{ backgroundColor: '#fff!important' }}>
  //           <img src={require('../images/canvendor_logo.jpg')} />
  //         </h1>

  return (
    <>
      <Navbar
        expand="lg"
        data-aos="fade-down"
        fixed="top"
        /*  style={{zIndex:'2',top:'0px',backgroundColor:'rgb(31,33,72)'}}*/
        style={{
          backgroundColor: navBackground ? "#fff" : "transparent",
          boxShadow: navBackground
            ? "0px 4px 20px 1px rgba(0,0,0,0.1)"
            : "none",
        }}
      >
        <Container>
          <Navbar.Brand href="#home">
            <img className="img-fluid" src={imageSrc} width={150} height={30} />
          </Navbar.Brand>
          <div className="brand">
            <img
              className="img-fluid"
              src="../assets/images/canvendor_blue_transparent_logo.png"
              width={150}
              height={30}
            />
          </div>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{
              border: "2px solid white",
              backgroundColor: "rgba(255, 255, 255, 0.527)",
            }}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link>
                <Link to="/" style={linkStyle}>
                  Home
                </Link>{" "}
              </Nav.Link>

              <li className="dropdown me-0 dropdown-link">
                <Link to="/services" className="nav-link " style={linkStyle}>
                  Capabilities
                  <i
                    class="fa fa-caret-down"
                    aria-hidden="true"
                    style={{ fontSize: 16 }}
                  ></i>
                </Link>
                <ul>
                  <li>
                    <Link to="/services/aiml">AI/ML SOLUTIONS</Link>
                  </li>
                  <li>
                    <Link to="/services/webAppService">
                      APPLICATION ENGINEERING
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/cloudSolutions">CLOUD SOLUTIONS</Link>
                  </li>
                  <li>
                    <Link to="/services/digitalTransformation">
                      DIGITAL TRANSFORMATION
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/enterpriseService">
                      ENTERPRISES SOLUTIONS
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/itConsultingService">
                      IT CONSULTING
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dropdown me-0 dropdown-link">
                <Link to="" className="nav-link scrollto" style={linkStyle}>
                  Industries
                  <i
                    class="fa fa-caret-down"
                    aria-hidden="true"
                    style={{ fontSize: 16 }}
                  ></i>
                </Link>
                <ul>
                  
                  <li>
                    <Link to="/industries/automotive">AUTOMOTIVE</Link>
                  </li>
                  <li>
                    <Link to="/industries/banking&finance">BANKING</Link>
                  </li>
                  {/* <li>
                    <Link to="/industries/communications&information">
                      COMMUNICATION
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/industries/healthcare">HEALTHCARE</Link>
                  </li>
                  <li>
                    <Link to="/industries/insurance">INSURANCE</Link>
                  </li>
                  <li>
                    <Link to="/industries/manufacturing">MANUFACTURING</Link>
                  </li>
                </ul>
              </li>
              {/* <Nav.Link ><Link to='' style={linkStyle}>Ecosystem</Link></Nav.Link> */}
              <Nav.Link>
                <Link to="/insights#SingleService" style={linkStyle}>
                  Insights
                </Link>
              </Nav.Link>

              <Nav.Link>
                <Link to="/about" style={linkStyle}>
                  About
                </Link>
              </Nav.Link>
              {/* <Nav.Link ><Link to='' style={linkStyle}>Resources</Link></Nav.Link> */}
              <Nav.Link>
                {" "}
                <Link to="/carrer" style={linkStyle}>
                  Careers
                </Link>
              </Nav.Link>
              <Nav.Link>
                {" "}
                <Link to="/contactUs" style={linkStyle}>
                  Contact
                </Link>
              </Nav.Link>
              {/* <Nav.Link><Link to='/enquiry'>Enquriy</Link></Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavComponentHome;
