import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const ContactCard = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <div className="container-fluid contact-card-bg">
        <div className="container contact-card-container">
          <div className="contact-card-img">
            <img src="/contact-card.png" />
          </div>
          <div className="contact-card-content">
            <h3>Let us hear from you</h3>
            <p>
              If you have any queries or need any information, contact us
              through contact@canvendor.com
            </p>
          </div>

          <Link to="/contactUs">
            <button className="contact-card-btn">Contact Us</button>
          </Link>
        </div>
        <img className="contact-card-bg-img" src="/contact-card-bg.png" />
      </div>
    </>
  );
};

export default ContactCard;
