import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Location = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <div className="container location-section-container">
        <h2 className="location-section-title" data-aos="fade-up">
          Our Global Presence
        </h2>
        <div className="row">
          
          <div className="col-xl-3 col-md-6  location-item-col">
            <div className="location-item" data-aos="fade-up">
              <img src="/america-bg.png" />
              <div className="home-location-content">
                <h3 className="location-continent">Americas</h3>
                <h5 className="location-name">USA</h5>
                <p className="location-address">
                  7567 Amador Valley Blvd, Suite 106, Dublin, CA - 94568.
                </p>
                <h5 className="location-name">Canada</h5>
                <p className="location-address">
                  Canvendor Software Corp 1103-11871 Horseshoe Way Vancouver
                  British Columbia V7A 5H5.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 location-item-col">
            <div className="location-item" data-aos="fade-up">
              <img src="/europe-bg.jpg" />
              <div className="home-location-content location-content-center">
                <h3 className="location-continent">Europe</h3>
                <h5 className="location-name">United Kingdom</h5>
                <p className="location-address">
                  Canvendor Limited 2 Woodberry Grove London N12 0DR.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 location-item-col">
            <div className="location-item" data-aos="fade-up">
              <img src="/mena-bg.jpg" />
              <div className="home-location-content location-content-center">
                <h3 className="location-continent">MENA</h3>
                <h5 className="location-name">UAE</h5>
                <p className="location-address">
                  CANVENDOR CORP LLC FZ., M Floor, The Meydan Hotel, Al Sheba,
                  Dubai.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 location-item-col">
            <div className="location-item " data-aos="fade-up">
              <img src="/apac-bg.jpg" />
              <div className="home-location-content ">
                <h3 className="location-continent">APAC</h3>
                <h5 className="location-name">INDIA - Nagercoil</h5>
                <p className="location-address">
                  Canvendor Software Solutions pvt. 19/3 PRK Square, Court Road
                  Nagercoil - 629001, Tamilnadu.
                </p>
                <h5 className="location-name">INDIA - Chennai</h5>
                <p className="location-address">
                  M63,Cactus Corporate, Suite#173, 7th Floor, Block B, Tecci
                  Park, OMR, Sholinganallur Chennai - 600119, Tamilnadu.
                </p>

                <h5 className="location-name">Singapore</h5>
                <p className="location-address">
                  CANVENDOR PTE.LTD. 9 Raffles Place #29-05 Republic Plaza,
                  Singapore(048619).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Location;
