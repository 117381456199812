import React from "react";
import NavComponent from "../../components/NavComponent";
import { Link } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const AimlService = () => {
  useEffect(() => {   
    window.scrollTo(0, 0); 
   }, []);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <NavComponent></NavComponent>
      <section className="aiml-services-page-head-bg overflow-hidden">
        <div className="container service-bg-container ">
          <div>
            <h1 className="service-inner-page-title" data-aos="flip-down">AI/ML Solutions</h1>
            <div className="page-direction" data-aos="fade-up">
              <span className="ps-0">
                <Link to="/">Home</Link>
              </span>
              <span>
                <Link to="/services">capabilities</Link>
              </span>
              <p>AI/ML Solutions</p>
            </div>
          </div>
          <img className="aiml-img" src="/aiml-services-page-img.jpg" data-aos="fade-left" data-aos-duration="3000" />
        </div>
      </section>

      <div className="container service-content-section">
        <div className="row">
          <div className="col-lg-10">
            <div className="">
              <span className="section-sub-title">
                Our misson is grow your Business
              </span>
              <h2 className="service-page-content-title">
                Artificial Intelligence and Machine Learning{" "}
              </h2>
              <p>
                {" "}
                At Canvendor, we understand the challenges of collecting,
                processing, and analyzing vast amounts of valuable data.Our AI
                and ML solutions streamline these processes, devising robust
                data strategies that empower you to make informed and
                intelligent decisions.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid aiml-our-offerings">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h1 className="service-page-offering-title">Our Offerings</h1>
              <p className="service-page-offering-para">
                We deliver advanced solutions tailored to boost your business
                through digital transformation, strategic insights, and
                AI-driven innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container service-card-margin">
        <div className="service-offering-section">
          <div className="service-offering-card">
            <div className="">
              <div className="service-offering-card-icon">
                <img src="/Application Modernization.png" />
              </div>
              <h3 className="service-offering-card-title">
                Application Modernization
              </h3>
              <p className="service-offering-card-content">
                At Canvendor, we excel in moving legacy databases to modern,
                efficient systems. While the task of migrating databases to the
                digital world can seem daunting, it ultimately enhances user
                experience and drives long-term success.
              </p>
            </div>
          </div>
          <div className="service-offering-card ">
            <div className="">
              <div className="service-offering-card-icon">
                <img src="/AI-Powered Strategic Decision Making.png" />
              </div>
              <h3 className="service-offering-card-title">
                AI-Powered Strategic Decision Making
              </h3>
              <p className="service-offering-card-content">
                Empower your business leaders with AI and ML tools for strategic
                decision-making. Armed with advanced algorithms, leaders can
                make informed choices that positively impact the company’s
                trajectory.
              </p>
            </div>
          </div>
          <div className="service-offering-card  ">
            <div className="">
              <div className="service-offering-card-icon">
                <img src="/AI-Driven Company Insights.png" />
              </div>
              <h3 className="service-offering-card-title">
                AI-Driven Company Insights
              </h3>
              <p className="service-offering-card-content">
                Top leaders leverage AI to understand what is happening and how
                to act proactively. Our AI solutions provide comprehensive
                resource visibility for better decision-making.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container service-content-section">
        <div className="row">
          <div className="col-lg-10">
            <div className="">
              <span className="section-sub-title">Consulting</span>
              <h2 className="service-page-content-title">AI/ ML Solutions </h2>
              <p>
                {" "}
                At Canvendor, we excel in IT consulting with a specialization in
                Artificial Intelligence (AI) and Machine Learning (ML). Our
                expert team is dedicated to addressing a wide range of business
                challenges throughout your digital transformation journey. We
                focus on bridging gaps and creating seamless roadmaps, ensuring
                that your business gains a competitive edge through cutting-edge
                AI and ML technologies. Trust us to enhance your operations with
                tailored AI and ML solutions designed for growth and innovation.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AimlService;
